import styled from "styled-components";
import React, { FC } from "react";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  padding: 5px 8px;
  gap: 5px;
  border-radius: 8px;
  color: #eee;
  background: rgba(0, 0, 0, 0.5);
  font-weight: bold;
`;
const Status = styled.div`
  @keyframes status-color {
    60% {
      color: red;
    }
    80% {
      color: darkred;
    }
  }

  color: #f00;
  display: inline-flex;
  animation-name: status-color;
  animation-duration: 4s;
  animation-iteration-count: infinite;
`;

export interface RecordingBadgeProps {
  text: string;
}

export const RecordingBadge: FC<RecordingBadgeProps> = ({ text }) => {
  return (
    <Wrapper>
      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
      <Status>● </Status>
      {text}
    </Wrapper>
  );
};
