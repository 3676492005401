import React from "react";
import { Participants } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { modalToggle } from "../Modal/Modals";

const ParticipantsButton = () => {
  const { participants } = useStore(
    useShallow((state) => ({
      participants: state.participants,
    })),
  );

  return (
    <MenuButton
      buttonIcon={
        <>
          <Participants stroke="white" />
          <span className="badge">{Object.values(participants).length}</span>
        </>
      }
      onClick={() => {
        modalToggle("ManageParticipants");
      }}
    >
      <FormattedMessage
        description="Participants menu button text"
        id="participants_button.label"
        defaultMessage="Participants"
      />
    </MenuButton>
  );
};

export default ParticipantsButton;
