import axios from "axios";

export default async (signal: AbortSignal): Promise<UserResponse> => {
  return axios
    .get(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/session_app/me`, {
      signal,
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
};
