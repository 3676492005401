{
  "actions.content_waiting_for_partner": "In attesa di partner",
  "audio_level_inner.indicator": "Indicatore del livello audio",
  "blur_background.blur_background": "Sfocatura",
  "blur_background.unblur_background": "Sbur",
  "camera_area.permission_camera_error": "Controlla se il tuo browser ha i permessi.",
  "camera_area.refresh_camera_error": "E prova ad aggiornare o riavviare il browser.",
  "camera_button.camera_off": "Spegni",
  "camera_button.camera_on": "Attiva",
  "chat.offline": "Offline (Chat disabilitata)",
  "chat.online": "In linea",
  "chat.partner_status": "Il tuo partner è: {status}",
  "chat.username_you": "Voi",
  "connection_issue_badge.heading": "Disconnesso",
  "content.and": "e",
  "content.iframe_default_message": "Preparazione del prossimo passo...",
  "content.your_partners": "i tuoi partner",
  "content.yourself": "te stesso",
  "countdown_timer.time_left": "Tempo rimasto",
  "determine_content.and": "e",
  "determine_content.iframe_default_message": "Preparazione del prossimo passo...",
  "determine_content.your_partners": "i tuoi partner",
  "determine_content.yourself": "te stesso",
  "drop_participant_modal.cancel": "Annulla",
  "drop_participant_modal.drop_user": "Elimina utente",
  "drop_participant_modal.header": "Rimuovi utente dalla sessione",
  "drop_participant_modal.this_will_rewind": "Questo riavvolgerà il flusso di alcuni passaggi all'inizio della sezione corrente.",
  "drop_participant_modal.you_can_drop_a_user": "Puoi eliminare un utente che ritieni non tornerà in modo che il flusso possa continuare.",
  "feedback_score_step.content_waiting_for_partner": "In attesa di partner",
  "feedback_words_step.content_waiting_for_partner": "In attesa di partner",
  "feeling_survey.happy_label": "Felice, sollevato",
  "feeling_survey.intro_question": "Prima di iniziare, come ti senti adesso?",
  "feeling_survey.sad_label": "Triste, giù",
  "manage_participant_modal.drop": "Rimuovere",
  "manage_participant_modal.drop_confirmation": "Sei sicuro di voler eliminare {participant_name} dalla sessione?",
  "manage_participant_modal.drop_from_the_session": "Elimina {participant_name} dalla sessione",
  "manage_participant_modal.header": "Partecipanti",
  "manage_participant_modal.offline": "Disconnessa",
  "manage_participant_modal.online_participants": "Partecipanti online",
  "manage_participant_modal.tile.you": "(tu)",
  "manage_participant_modal.total_participants": "Partecipanti totali",
  "manage_participants_modal.cancel": "Annulla",
  "manage_participants_modal.drop_participant": "Rimuovere {participant_name}",
  "mic_button.mute": "Muto",
  "mic_button.unmute": "Riattiva",
  "more_menu.enter-fullscreen": "A schermo intero",
  "participants_button.label": "Partecipanti",
  "prejoin.join_digital_button": "Unisciti",
  "prejoin.setup_camera_started": "Videocamera avviata",
  "screenshare_button": "Condivisione dello schermo",
  "switch_digital_physical_modal.cancel": "Annulla",
  "tile_no_video.disconnected": "Disconnesso",
  "tile_no_video.drop_user": "Elimina utente",
  "tile_no_video.getting_ready": "Mi sto preparando",
  "tile_no_video.location": "{locationMessage}",
  "tile_no_video.username_you": "Voi",
  "username.has_not_completed_step": "{name} non ha ancora completato questo passaggio",
  "username.you": "Voi",
  "waiting_for_partner.more_than_10_minutes": "Sono passati più di 10 minuti e molto probabilmente il tuo partner non si farà vedere.",
  "waiting_for_partner.partner_getting_ready": "Il tuo partner si sta preparando.",
  "waiting_for_partner.partner_might_not_show": "Il tuo partner potrebbe non presentarsi.",
  "waiting_for_partner.schedule_new": "Pianifica un nuovo Warmspace",
  "waiting_for_partner.session_id": "ID sessione: {sessionId}",
  "waiting_for_partner.sorry": "Siamo spiacenti che tu non possa avere una sessione in questo momento. Prendi in considerazione la possibilità di programmare una nuova sessione.",
  "waiting_for_partner.waiting_for_partner": "Aspetto che il tuo partner si unisca a te.",
  "breakout_button": "Flows",
  "breakout_modal.title": "Flow",
  "breakout_room.roomName": "Sala {numero}: {nome} ({currentStep}/{totalSteps})",
  "breakout_rooms.close": "Chiuso",
  "breakout_rooms.closeAll": "Chiudi tutto",
  "breakout_rooms.lobby": "Atrio",
  "breakout_rooms.noParticipants": "Tutti i partecipanti sono attualmente in una sessione",
  "breakout_rooms.onGoingRooms": "In corso ({quantity} camere)",
  "camera_area.error_message": "Messaggio di errore: \"{error}\"",
  "chat.heading": "Chat",
  "chat_button": "Chat",
  "connection_issue_badge.text": "Disconnesso",
  "determine_content.end_call": "Termina la chiamata e dai feedback",
  "entities.slash": "&#47;",
  "flows_list.button_start": "Cominciare",
  "flows_list.groups_gather_all": "raduna tutti",
  "flows_list.min_groups": "in gruppi di {min}",
  "flows_list.min_max_groups": "in gruppi di {min} - {max} (preferito: {preferred})",
  "leave_button": "Lasciare",
  "lobby_status_badge.breakout": "Flow",
  "lobby_status_badge.leave_room": "Lasciare la stanza",
  "lobby_status_badge.lobby_title": "Atrio",
  "lobby_status_badge.re_join": "Partecipa nuovamente",
  "lobby_status_badge.room_closing_in": "La stanza si chiude tra {actualSecondsRemaining} secondi",
  "lost_connection": "Tentativo di riconnessione",
  "manage_participant_modal.participant_name": "{participant_name}",
  "matching_check_box.noParticipants": "Nessun partecipante disponibile da abbinare",
  "matching_check_box.return_to_room": "Ritorno in camera",
  "more_button.menu": "Menù",
  "more_button.more": "Più",
  "more_menu.exit-fullscreen": "Uscire dallo schermo intero",
  "prejoin.setup_camera_off": "videocamera spenta",
  "prejoin.setup_starting_camera": "Avvio della videocamera",
  "prejoin.setup_state_off_starting_camera": "Avvio della videocamera",
  "smart_button_fixture.content.Done": "Fatto",
  "start_new_breakout.clearAll": "Cancella tutto",
  "start_new_breakout.flows": "Flows",
  "start_new_breakout.includeInMatching": "Includi nel raggruppamento",
  "start_new_breakout.selectAll": "Seleziona tutto",
  "switch_digital_physical_button.in_person": "In persona",
  "switch_digital_physical_button.video": "Video",
  "switch_physical_digital_modal.digital_switch_description": "La modalità video abilita la fotocamera e il microfono ed è ottimizzata per eseguire un Warmspace su una chat video.",
  "switch_physical_digital_modal.heading": "Modalità di commutazione",
  "switch_physical_digital_modal.join_digital": "Cambia modalità",
  "switch_physical_digital_modal.join_physical": "Cambia modalità",
  "switch_physical_digital_modal.physical_switch_description": "Questa disattiva la fotocamera e il microfono ed è ottimizzata per una connessione di persona.",
  "test": "test",
  "username.has_completed_step": "{name} ha completato questo passo",
  "vU4qZm": "Invia",
  "waiting_for_partner.waiting_for_partners": "Aspetto che i tuoi partner si uniscano a te."
}
