import React from "react";
import { AnalyticsButtonIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { modalClearAll } from "../Modal/Modals";

const AnalyticsButton = () => {
  const { externalContentUrl, showExternalContent } = useStore(
    useShallow((state) => ({
      externalContentUrl: state.externalContentUrl,
      showExternalContent: state.showExternalContent,
    })),
  );

  return externalContentUrl ? (
    <MenuButton
      buttonIcon={<AnalyticsButtonIcon stroke={showExternalContent ? "#FFA100" : "white"} />}
      onClick={() => {
        modalClearAll();
        useStore.setState({ showExternalContent: !showExternalContent, showChat: false });
      }}
      className={showExternalContent ? "selected" : ""}
    >
      <FormattedMessage id="analytics_button.label" defaultMessage={"Analytics"} />
    </MenuButton>
  ) : (
    <></>
  );
};

export default AnalyticsButton;
