import styled from "styled-components";
import React, { useState, FC } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Close } from "../../../assets/icons/Icons";
import { Overlay, Card, CardBody, CardHeader, CardFooter } from "../Modal/ModalStyle";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { can } from "../../../helpers/can";
import { muteAll } from "../../../core/SessionChannel/muteAll";
import { muteParticipant } from "../../../core/SessionChannel/muteParticipant";
import { modalClearAll } from "../Modal/Modals";

const ManageParticipantsWrapper = styled.div`
  max-height: 60vh;
  overflow: auto;
  width: 100%;
  padding-bottom: 10px;
`;

const Participant = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 5px;
  padding-left: 0px;
  overflow: visible;

  i {
    margin-left: 6px;
    font-size: 12px;
    color: #555;
  }
`;

const SmallButton = styled(Button)`
  background: #222;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 10px;
`;

const DropParticipantModalWrapper = styled.div`
  display: flex;
  button {
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
  }
  .cancelButton {
    border: none;
    background-color: transparent;
    padding: 0px;
    text-decoration: underline;
    box-shadow: none !important;
    padding: 0px !important;
    color: #222;
  }
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: 0px;
  margin-bottom: 3px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

interface Props {
  joinedParticipants: Participant[];
  dropParticipant: (participantID: number) => void;
  yourId: number;
}
export const ManageParticipantModal: FC<Props> = ({ joinedParticipants, dropParticipant, yourId }) => {
  const intl = useIntl();
  const [showDropParticipantModal, setShowDropParticipantsModal] = useState(false);
  const [participant, setParticipant] = useState<Participant>();

  const { inPerson, currentUser, invitation } = useStore(
    useShallow((state) => ({
      inPerson: state.inPerson,
      currentUser: state.currentUser,
      invitation: state.invitation,
    })),
  );

  const participantSort = (a: Participant, b: Participant) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  //Splitting array for offline vs online user
  const [offlineParticipants, onlineParticipants] = joinedParticipants.reduce(
    (result, p) => {
      result[p.location === "offline" ? 0 : 1].push(p);
      return result;
    },
    [new Array<Participant>(), new Array<Participant>()],
  );

  //Sorting array by name
  offlineParticipants.sort(participantSort);
  onlineParticipants.sort(participantSort);

  const renderParticipants = (participant: Participant, i: number, location: string) => {
    return (
      <Participant key={`drop-user-${i}`} data-testid={`participant-${participant.name}`}>
        <div style={{ display: "flex" }}>
          <div key={`drop-user-name-${i}`}>
            {participant.name}
            {participant.authorizations.length > 0 && (
              <FormattedMessage
                description="Text indicating user role"
                id="manage_participant_modal.role"
                defaultMessage="({role})"
                values={{ role: participant.authorizations.join(",") }}
              />
            )}
            <i>
              {participant.location === "ready-room" ? (
                <FormattedMessage
                  description="Participant location is getting ready"
                  id="manage_participant_modal.getting_ready"
                  defaultMessage=" (Getting ready)"
                />
              ) : (
                ""
              )}
            </i>
            {participant?.id == yourId
              ? intl.formatMessage({
                  description: "Text indicating your user tile as you",
                  id: "manage_participant_modal.tile.you",
                  defaultMessage: "(you)",
                })
              : ""}
            &nbsp;
          </div>
          {location === "offline" ? (
            <i>
              <FormattedMessage
                description="Participant location is offline"
                id="manage_participant_modal.offline"
                defaultMessage="Offline"
              />
            </i>
          ) : (
            ""
          )}
        </div>
        {participant.location === "session" && can("muteOthers", currentUser, invitation) && !inPerson && (
          <SmallButton
            onClick={() => {
              muteParticipant(participant.id);
            }}
            style={{ width: "auto" }}
          >
            <FormattedMessage
              description="Participant button text to mute a participant"
              id="manage_participant_modal.mute "
              defaultMessage="Mute"
            />
          </SmallButton>
        )}
        {location === "offline" && (
          <SmallButton
            onClick={() => {
              setShowDropParticipantsModal(true);
              setParticipant(participant);
            }}
            style={{ width: "auto" }}
          >
            <FormattedMessage
              description="Participant button text to drop participant"
              id="manage_participant_modal.drop"
              defaultMessage="Drop"
            />
          </SmallButton>
        )}
      </Participant>
    );
  };

  return (
    <>
      {!showDropParticipantModal ? (
        <Card data-testid="participants-modal">
          <CardHeader>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <FormattedMessage
                description="Participants Modal header text"
                id="manage_participant_modal.header"
                defaultMessage="Participants"
              />
              &nbsp;
              <span
                title={intl.formatMessage({
                  description: "Text indicating online participants on participant modal",
                  id: "manage_participant_modal.online_participants",
                  defaultMessage: "Online participants",
                })}
              >
                <FormattedNumber value={onlineParticipants.length} />
              </span>
              <FormattedMessage id="entities.slash" defaultMessage="&#47;" />
              <span
                title={intl.formatMessage({
                  description: "Text indicating total participants",
                  id: "manage_participant_modal.total_participants",
                  defaultMessage: "Total participants",
                })}
              >
                <FormattedNumber value={joinedParticipants.length} />
              </span>
              {can("muteOthers", currentUser, invitation) && !inPerson && (
                <SmallButton
                  onClick={() => {
                    muteAll();
                  }}
                  style={{ width: "auto" }}
                >
                  <FormattedMessage
                    description="Button text to mute all participants"
                    id="manage_participant_modal.mute_all"
                    defaultMessage="Mute all"
                  />
                </SmallButton>
              )}
            </div>

            <Button
              className="link"
              onClick={() => {
                modalClearAll();
              }}
            >
              <Close />
            </Button>
          </CardHeader>
          <CardBody>
            <ManageParticipantsWrapper>
              {offlineParticipants.map((p, i) => renderParticipants(p, i, "offline"))}
              {onlineParticipants.map((p, i) => renderParticipants(p, i, "online"))}
            </ManageParticipantsWrapper>
          </CardBody>
        </Card>
      ) : (
        <DropParticipantModalWrapper data-testid={"drop-participant-modal"}>
          <Card>
            <CardHeader>
              <FormattedMessage
                description="Drop participant header text indicating which participant to drop from the session"
                id="manage_participant_modal.drop_from_the_session"
                defaultMessage="Drop {participant_name} from the session"
                values={{ participant_name: participant?.name }}
              />
            </CardHeader>
            <CardBody>
              <FormattedMessage
                description="Text confirmation to drop participant from session"
                id="manage_participant_modal.drop_confirmation"
                defaultMessage="Are you sure you want to drop {participant_name} from the session?"
                values={{ participant_name: participant?.name }}
              />
            </CardBody>
            <CardFooter>
              <Button
                onClick={async () => {
                  setShowDropParticipantsModal(false);
                }}
                className="link"
                style={{ color: "black" }}
              >
                <FormattedMessage
                  description="Cancel managing participants button text"
                  id="manage_participants_modal.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                style={{ width: " auto" }}
                onClick={async () => {
                  if (participant) {
                    await dropParticipant(participant.id);
                  }
                  setShowDropParticipantsModal(false);
                }}
              >
                <FormattedMessage
                  description="Drop participant button text"
                  id="manage_participants_modal.drop_participant"
                  defaultMessage="Drop {participant_name}"
                  values={{ participant_name: participant?.name }}
                />
              </Button>
            </CardFooter>
          </Card>
        </DropParticipantModalWrapper>
      )}
      <Overlay
        onClick={() => {
          modalClearAll();
        }}
      />
    </>
  );
};

export default ManageParticipantModal;
