import React, { FC } from "react";
import { Grommet } from "grommet";
import WarmspaceTheme from "../../theme";

import { DailyProvider } from "@daily-co/daily-react";
import { DailyCall } from "@daily-co/daily-js";
import { ThemeProvider } from "styled-components";
import { getThemeByName } from "../../lib/theme";
import { IntlShape, RawIntlProvider } from "react-intl";

export const Providers: FC<{
  children: React.ReactElement | null;
  callObject?: DailyCall;
  intl: IntlShape;
}> = ({ children, callObject, intl }) => {
  console.debug("[session-ui]: Provider render");

  // TODO: We're transitioning to using ThemeProvider instead of Grommet, but right now we have both.
  return (
    <RawIntlProvider value={intl}>
      <Grommet
        theme={WarmspaceTheme}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DailyProvider callObject={callObject}>
          <ThemeProvider theme={getThemeByName("default")}>{children}</ThemeProvider>
        </DailyProvider>
      </Grommet>
    </RawIntlProvider>
  );
};
