export const ROLES: Roles[] = ["A", "B", "EVERYONE_ELSE"];
export const ROLE_A: Roles = "A";
export const ROLE_B: Roles = "B";
export const EVERYONE_ELSE: Roles = "EVERYONE_ELSE";

export const DEFAULT_CHOICE_LIST_WIDTH = 96;
export const DEFAULT_CHOICE_COLUMNS = 3;
export const DEFAULT_CHOICE_BUTTON_HEIGHT = "auto";
export const DEFAULT_CHOICE_ACTION = "done";
export const DEFAULT_LOCALE = "en";
export const DEFAULT_MAX_GROUP_SIZE = 8;

export const RESERVED_DATA_NAMES = ["userResponses", "aName", "bName", "yourName"];
export const BLUR_LEVEL = 0.5;

export const PARTICIPANTS_PER_PAGE = 12;
