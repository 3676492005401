import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import styled from "styled-components";

const Wrapper = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  li {
    border-radius: 100px;
    width: 40px;
    display: inline-block;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    cursor: pointer;
    background-color: #f19903 !important;
  }

  li:hover {
    background-color: #fdc25d;
  }

  li:last-child {
    margin-right: 0px;
  }
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
`;

interface FeelingSurveyProps {
  submitFeeling: (feeling: number) => void;
}

export const FeelingSurvey: React.FC<FeelingSurveyProps> = ({ submitFeeling }) => {
  const intl = useIntl();

  return (
    <>
      <h3 style={{ fontWeight: "normal", textAlign: "center" }}>
        <FormattedMessage
          description="Feeling Survey intro question"
          id="feeling_survey.intro_question"
          defaultMessage="Before we begin, how are you feeling now?"
        />
      </h3>

      <Wrapper>
        <li onClick={submitFeeling.bind(undefined, 1)}>{intl.formatNumber(1)}</li>
        <li onClick={submitFeeling.bind(undefined, 2)}>{intl.formatNumber(2)}</li>
        <li onClick={submitFeeling.bind(undefined, 3)}>{intl.formatNumber(3)}</li>
        <li onClick={submitFeeling.bind(undefined, 4)}>{intl.formatNumber(4)}</li>
        <li onClick={submitFeeling.bind(undefined, 5)}>{intl.formatNumber(5)}</li>
        <li onClick={submitFeeling.bind(undefined, 6)}>{intl.formatNumber(6)}</li>
        <li onClick={submitFeeling.bind(undefined, 7)}>{intl.formatNumber(7)}</li>
        <li onClick={submitFeeling.bind(undefined, 8)}>{intl.formatNumber(8)}</li>
        <li onClick={submitFeeling.bind(undefined, 9)}>{intl.formatNumber(9)}</li>
        <li onClick={submitFeeling.bind(undefined, 10)}>{intl.formatNumber(10)}</li>
      </Wrapper>
      <Labels>
        <div>
          <FormattedMessage
            description="Feeling Survey sad label"
            id="feeling_survey.sad_label"
            defaultMessage="Sad, down"
          />
        </div>
        <div>
          <FormattedMessage
            description="Feeling Survey happy label"
            id="feeling_survey.happy_label"
            defaultMessage="Happy, uplifted"
          />
        </div>
      </Labels>
    </>
  );
};
