import React from "react";
import { AddPartnerIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";

const AddPartnerButton = () => {
  const { showAddPartner, invitation, currentUser, participants } = useStore(
    useShallow((state) => ({
      invitation: state.invitation,
      currentUser: state.currentUser,
      participants: state.participants,
      showAddPartner: state.showAddPartner,
    })),
  );

  const curParticipant = participants[currentUser.id];

  const toggleShowAddPartner = () => {
    useStore.setState((state) => ({ showAddPartner: !state.showAddPartner }));
  };

  if (!curParticipant?.personalCode || invitation.lobby || !invitation.partnerJoinable) {
    return;
  }

  return (
    <MenuButton
      buttonIcon={<AddPartnerIcon stroke={showAddPartner ? "#FFA100" : "white"} />}
      onClick={toggleShowAddPartner}
      className={showAddPartner ? "selected" : ""}
    >
      <FormattedMessage
        description="Menu button text to toggle Add Partner"
        id="add_partner.add_partner"
        defaultMessage="Add Partner"
      />
    </MenuButton>
  );
};

export default AddPartnerButton;
