import React from "react";
import { FormattedMessage } from "react-intl";
import { ConfigHeader, InfoText, Tracker } from "../../Modal/ModalStyle";
import { ClockIcon } from "../../../../assets/icons/Icons";
import styled from "styled-components";
import { useStore } from "../../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { secondsToTimeString } from "../../Badge/LobbyStatusBadge";
import { getFlowTimeString } from "../FlowItem";

const TrackerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000;
`;

interface DurationOption {
  durationPreference: DurationPreference;
  groupSize: number | undefined;
}

export const FlowDurationConfig = () => {
  const { currentFlowConfig } = useStore(
    useShallow((state) => ({
      currentFlowConfig: state.currentFlowConfig as LobbyInvitation,
    })),
  );

  // Nothing to do here if the flow doesn't have step durations configured
  if (!currentFlowConfig.duration) return;

  let curDurationSec: number;
  switch (currentFlowConfig.durationPreference) {
    case "Short":
      curDurationSec = currentFlowConfig.minDuration!;
      break;
    case "Long":
      curDurationSec = currentFlowConfig.maxDuration!;
      break;
    case "Avg":
      curDurationSec = !currentFlowConfig.minDuration
        ? currentFlowConfig.duration
        : Math.round((currentFlowConfig.minDuration! + currentFlowConfig.maxDuration!) / 2);
      break;
    case "Default":
      curDurationSec = currentFlowConfig.duration;
      break;
  }

  const durationOptions: DurationOption[] = [];
  durationOptions.push({
    durationPreference: "Default",
    groupSize: undefined,
  });
  if (currentFlowConfig.minGroupSize != currentFlowConfig.maxGroupSize) {
    durationOptions.push({
      durationPreference: "Short",
      groupSize: currentFlowConfig.minGroupSize,
    });
    durationOptions.push({
      durationPreference: "Avg",
      groupSize: undefined,
    });
    durationOptions.push({
      durationPreference: "Long",
      groupSize: currentFlowConfig.maxGroupSize,
    });
  }

  function setDurationPreference(newDurationPreference: DurationPreference) {
    const updatedFlowConfig = Object.assign({}, currentFlowConfig);
    if (updatedFlowConfig.durationPreference !== newDurationPreference) {
      updatedFlowConfig.durationPreference = newDurationPreference;
      useStore.setState({ currentFlowConfig: updatedFlowConfig });
    }
  }

  return (
    <>
      <ConfigHeader style={{ padding: "10px 10px 5px 0" }}>
        <ClockIcon stroke="#000" />
        <FormattedMessage id="flow_duration.title" defaultMessage="Flow Duration" />
      </ConfigHeader>
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <InfoText>
          {currentFlowConfig.minGroupSize === currentFlowConfig.maxGroupSize ? (
            <FormattedMessage
              id="flow_duration.message_nothingToAdjust"
              defaultMessage="Groups will finish at the same time in {timeString}. Nothing to adjust."
              values={{
                timeString: secondsToTimeString(curDurationSec),
              }}
            />
          ) : currentFlowConfig.durationPreference == "Default" ? (
            <FormattedMessage
              id="flow_duration.message_default"
              defaultMessage="Groups of {minGroupSize}-{maxGroupSize} people will finish in {timeString} min"
              values={{
                timeString: getFlowTimeString(currentFlowConfig),
                minGroupSize: currentFlowConfig.minGroupSize,
                maxGroupSize: currentFlowConfig.maxGroupSize,
              }}
            />
          ) : currentFlowConfig.durationPreference == "Avg" ? (
            <FormattedMessage
              id="flow_duration.message_avg"
              defaultMessage="Averarge short/long timings so they all finish in {timeString} min"
              values={{
                timeString: secondsToTimeString(curDurationSec),
              }}
            />
          ) : (
            <FormattedMessage
              id="flow_duration.message_min_max"
              defaultMessage="Adjust timings so all groups finish like a group of {groupSize} people in {timeString} min"
              values={{
                timeString: secondsToTimeString(curDurationSec),
                groupSize:
                  currentFlowConfig.durationPreference == "Short"
                    ? currentFlowConfig.minGroupSize
                    : currentFlowConfig.maxGroupSize,
              }}
            />
          )}
        </InfoText>
        <TrackerWrapper>
          {durationOptions.map((durationOption) => {
            return (
              <Tracker
                key={durationOption.durationPreference}
                onClick={() => {
                  setDurationPreference(durationOption.durationPreference);
                }}
                selected={durationOption.durationPreference === currentFlowConfig.durationPreference}
              >
                {!durationOption.groupSize ? (
                  durationOption.durationPreference
                ) : (
                  <FormattedMessage
                    id="flow_duration.option"
                    defaultMessage="{durationPreference}"
                    values={{
                      durationPreference: durationOption.durationPreference,
                    }}
                  />
                )}
              </Tracker>
            );
          })}
        </TrackerWrapper>
      </div>
    </>
  );
};
