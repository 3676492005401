import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  pages: string[];
  activePage: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
}

const Wrapper = styled.div`
  display: flex;
  background-color: #dfc6a5;
  padding: 6px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #daceaf;
  box-shadow: inset 0px 0px 3px #c6b381;
`;

const ToggleItem = styled.div`
  font-size: 16px;
  color: #000;
  padding: 5px;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #fff7ea;
  }

  &:last-child {
    margin-right: 0px;
  }

  &.active {
    background-color: #fff7ea;
    color: #000;
    font-weight: bold;
  }
`;

export const PageToggle: FC<Props> = ({ pages, setPage, activePage }) => {
  return (
    <Wrapper>
      {pages.map((page) => {
        return (
          <ToggleItem className={page === activePage ? "active" : ""} onClick={() => setPage(page)} key={page}>
            {page}
          </ToggleItem>
        );
      })}
    </Wrapper>
  );
};
