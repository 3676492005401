export const toggleFullscreen = (setIsFullScreen: (x: boolean) => void, isFullscreen: boolean): void => {
  const elem = document.documentElement;
  if (isFullscreen) {
    if (document.exitFullscreen) document.exitFullscreen();
    // @ts-ignore
    if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    // @ts-ignore
    if (document.mozCancelFullscreen) document.mozCancelFullscreen();
    // @ts-ignore
    if (document.msExitFullscreen) document.msExitFullscreen();
    return;
  }

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
    // @ts-ignore
  } else if (elem.webkitRequestFullscreen) {
    // @ts-ignore
    elem.webkitRequestFullscreen();
    // @ts-ignore
  } else if (elem.msRequestFullscreen) {
    // @ts-ignore
    elem.msRequestFullscreen();
  }

  setIsFullScreen(true);
};

export const handleFullscreen = (setIsFullScreen: (x: boolean) => void): void => {
  const fullscreenElement =
    document.fullscreenElement ||
    // @ts-ignore
    document.webkitFullscreenElement ||
    // @ts-ignore
    document.msFullscreenElement;

  setIsFullScreen(!!fullscreenElement);
};

export const applyDocumentHandlers = (handler: () => void): void => {
  document.onfullscreenchange = handler;
  // @ts-ignore
  document.onwebkitfullscreenchange = handler;
  // @ts-ignore
  document.onmozfullscreenchange = handler;
  // @ts-ignore
  document.onMSFullscreenChange = handler;
};
