import styled from "styled-components";

export const Content = styled.div`
  position: absolute;
  z-index: 10;
  opacity: 0;
  font-size: 20px;
  top: -40px;
  left: 2px;
  right: 2px;
  display: flex;
  padding: 0px 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  overflow: hidden;

  pre {
    padding: 0px;
    margin: 0px;
    font-size: 20px;
  }

  &.showUI {
    font-size: 20px;
    top: 4px;
    opacity: 1;

    p {
      padding: 18px;
    }
  }

  p {
    font-family: "ITC Clearface", sans-serif;
    transition: all 0.3s ease;
    margin: 0px;
    padding: 18px;
    white-space: pre-wrap;
    background-color: rgba(30, 30, 30, 0.7);
    backdrop-filter: blur(10px);
    max-width: 700px;
    width: 100%;

    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
  }
`;
