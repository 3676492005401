import React, { useState, useEffect, useRef } from "react";
import shuffle from "../../../lib/shuffle";
import toast from "react-simple-toasts";
import { useShallow } from "zustand/react/shallow";
import { useStore } from "../../../core/store";

// Music - a background music player

const AUDIODAMPING = 8;

const cdnHost = "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com";
const audioFolder = "/session-app/session-app/2021-04-21/audio/bg_music/";

const audioFiles = [
  cdnHost + audioFolder + "Moonrise - Futuremono.mp3",
  cdnHost + audioFolder + "Peaceful Mind - Astron.mp3",
  cdnHost + audioFolder + "Pure Potentiality - Benjamin Martins.mp3",
  cdnHost + audioFolder + "Seclusion - The Tides.mp3",
  cdnHost + audioFolder + "new/" + "1722 - The Golden Peas.mp3",
  cdnHost + audioFolder + "new/" + "40 Hz Gamma Wave Chua - 369.mp3",
  cdnHost + audioFolder + "new/" + "Autumnal Passage (Delta Drone 3Hz) - Bruce Brus.mp3",
  cdnHost + audioFolder + "new/" + "But I Flow - Sayuri Hayashi Egnell.mp3",
  cdnHost + audioFolder + "new/" + "Cora_s Panorama - Cora Zea.mp3",
  cdnHost + audioFolder + "new/" + "Duende - Syntropy.mp3",
  cdnHost + audioFolder + "new/" + "Im Ok Youre Ok - Sayuri Hayashi Egnell.mp3",
  cdnHost + audioFolder + "new/" + "Kaguya - Sayuri Hayashi Egnell.mp3",
  cdnHost + audioFolder + "new/" + "Kalpa - Sayuri Hayashi Egnell.mp3",
  cdnHost + audioFolder + "new/" + "Lost Thoughts - Hanna Lindgren.mp3",
  cdnHost + audioFolder + "new/" + "Lupi - Sayuri Hayashi Egnell.mp3",
  cdnHost + audioFolder + "new/" + "Moon over the Sea - Sayuri Hayashi Egnell.mp3",
  cdnHost + audioFolder + "new/" + "Oort Cloud - Amaranth Cove.mp3",
  cdnHost + audioFolder + "new/" + "Pure Conscience - Elm Lake.mp3",
  cdnHost + audioFolder + "new/" + "Silver Woodlands - Cora Zea.mp3",
  cdnHost + audioFolder + "new/" + "Threshold - Sayuri Hayashi Egnell.mp3",
  cdnHost + audioFolder + "new/" + "Where You Come From - Silver Maple.mp3",
  cdnHost + audioFolder + "new/" + "Your Turn - Silver Maple.mp3",
];

const shuffledAudio = shuffle(audioFiles);

function usePrevious(value: number) {
  const ref = useRef<number>();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
    // @ts-ignore (Ignore window.opera not existing)
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

const Music = () => {
  const { musicVolume, musicPlaying, mode, inPerson, invitation } = useStore(
    useShallow((state) => ({
      musicVolume: state.musicVolume,
      musicPlaying: state.musicPlaying,
      mode: state.mode,
      inPerson: state.inPerson,
      invitation: state.invitation,
    })),
  );

  // Start music on entering session
  // TODO: I think this could be moved to core, and actually all of the music
  // logic could be moved to core instead of being a react component.
  useEffect(() => {
    if (!invitation.disableMusic && mode === "session" && !inPerson) {
      useStore.setState({ musicPlaying: true });
    }

    if (invitation.disableMusic) {
      useStore.setState({ musicPlaying: false });
    }
  }, [invitation.disableMusic, mode]);

  const [audioIndex, setAudioIndex] = useState(0);
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [audioLoaded, setAudioLoaded] = useState(false);

  const prevAudioIndex = usePrevious(audioIndex);

  // Stop music if this component gets un-rendered.
  useEffect(() => {
    return () => {
      audio?.pause();
    };
  }, [audio]);

  // Load new audio when audioIndex changes
  useEffect(() => {
    if (isMobile()) return;
    if (audioIndex === prevAudioIndex) return;

    if (audio) audio.pause();

    setAudioLoaded(false);

    console.debug("[session-ui]: Loading music");

    const newAudio = new Audio(shuffledAudio[audioIndex]);

    newAudio.load();

    setAudio(newAudio);

    newAudio.addEventListener("canplaythrough", () => {
      setAudioLoaded(true);
    });

    newAudio.crossOrigin = "anonymous";

    console.debug("[session-ui]: Done loading music");
  }, [audioIndex, prevAudioIndex, audio]);

  // Add the 'on end' callback when a new audio is loaded
  useEffect(() => {
    if (!audio) return;

    audio.addEventListener("ended", () => {
      const nextIndex = (audioIndex + 1) % audioFiles.length;
      setAudioIndex(nextIndex);
    });
  }, [audio, audioIndex]);

  // Play/pause the audio depending on whether musicState.playing
  useEffect(() => {
    if (!audio) return;
    if (!audioLoaded) return;

    if (musicPlaying) {
      audio.controls = false;

      if (process.env.NODE_ENV === "test") toast("Music Playing");
      console.debug("[session-ui]: Playing music");
      audio.play().catch((e) => {
        console.debug("[session-ui]: Unable to play music");
        console.error(e);
      });
    }

    if (!musicPlaying) {
      console.debug("[session-ui]: Pausing music");
      audio.pause();
    }
  }, [musicPlaying, audioLoaded, audio]);

  // Adjust the volume of the audio depending on musicState.musicVolume
  useEffect(() => {
    if (!audio) return;

    audio.volume = musicVolume / AUDIODAMPING;
  }, [musicVolume, audio]);

  return <></>;
};

export default Music;
