import axios from "axios";

export default async (hashedInvitationID: string, feelingScore: number): Promise<string> => {
  return axios
    .post(
      `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/session_app/invitations/${hashedInvitationID}/session/feedback`,
      { feelingScore },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    )
    .then((response) => {
      return response.data;
    });
};
