import styled from "styled-components";
import React from "react";
import { BreakoutRoomIcon, ClockIcon, Close, Leave } from "../../../assets/icons/Icons";
import { doAction } from "../../../core/SessionChannel/doAction";
import postBreakoutReturn from "../../../lib/requests/postBreakoutReturn";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { FormattedMessage } from "react-intl";
import useCountdown from "../../../hooks/useCountDown";
import { modalClear, modalShow, modalToggle } from "../Modal/Modals";

const Status = styled.div`
  width: 100%;
  height: 33px;

  text-transform: uppercase;

  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  background: transparent;

  box-sizing: border-box;

  color: #eee;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  z-index: 115;

  svg {
    flex-shrink: 0;
    position: relative;
    top: -2px;
  }

  .title {
    padding-left: 3px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.buttons {
    flex-shrink: 0;
  }

  & .clickable-icon {
    cursor: pointer;
  }
`;

const CountdownBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  z-index: -1;
  width: 100%;
  background: #da3a00;
  transition: width 1s linear;
  opacity: 1;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0px;

  margin: auto;

  height: 40px;
  overflow: hidden;
  padding: 0px 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 5px;

  width: 100%;
  box-sizing: border-box;
  opacity: 1;
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  flex-shrink: 0;

  border-radius: 8px;

  font-size: 14px;
  line-height: 22px;
  background-color: #222;

  font-weight: bold;
  padding: 4px 10px;

  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    flex-shrink: 0;
    width: 17px;
    height: 17px;
    top: -1px;
    position: relative;
  }

  &:hover {
    background-color: #333;
  }
`;

const TimeRemaining = styled.span`
  color: #f5f5f5;
  font-size: 12px;
  font-size: 14px;
  line-height: 22px;
  background-color: #333;
  font-weight: bold;
  padding: 4px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 14px;
    top: -1px;
  }
`;

const LeaveButton = styled.button`
  background: none;
  border: none;

  flex-shrink: 0;

  border-radius: 8px;

  font-size: 14px;
  line-height: 22px;
  height: 30px;
  background-color: #833;

  font-weight: bold;
  padding: 4px 7px;

  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    top: -1px;
    width: 24px;
  }

  &:hover {
    background-color: #944;
  }
`;

export function secondsToTimeString(actualSecondsRemaining: number | number) {
  const secondPortionRemaining = actualSecondsRemaining % 60;
  const secondsString = secondPortionRemaining <= 9 ? `0${secondPortionRemaining}` : secondPortionRemaining;
  return `${Math.trunc(actualSecondsRemaining / 60)}:${secondsString}`;
}

export const LobbyStatusBadge = ({
  title,
  inRoom,
  expirationDate,
}: {
  title: string;
  inRoom: boolean;
  expirationDate?: Date;
}) => {
  const { confirmLeaveModalOpen, participants, deviceClassification, inPerson, sessionClosingType, currentUser, core } =
    useStore(
      useShallow((state) => ({
        participants: state.participants,
        deviceClassification: state.deviceClassification,
        inPerson: state.inPerson,
        sessionClosingType: state.sessionClosingType,
        currentUser: state.currentUser,
        core: state.core,
        confirmLeaveModalOpen: state.showModals.includes("ConfirmLeave"),
      })),
    );

  const actualSecondsRemaining = useCountdown(expirationDate);
  const secondsUntilBarVisible = sessionClosingType == "estimate" ? -1 : 20;
  const timeRemainingString = secondsToTimeString(actualSecondsRemaining);

  const toggleQrCodeModal = () => {
    modalToggle("QrCode");
  };

  return (
    <Wrapper
      className={[
        deviceClassification.size,
        actualSecondsRemaining > 0 ? "countingDown" : "",
        inPerson ? "inPerson" : "",
      ].join(" ")}
    >
      <Status>
        <div className="title">
          {(!actualSecondsRemaining || actualSecondsRemaining > secondsUntilBarVisible) && (
            <div className="clickable-icon" onClick={toggleQrCodeModal}>
              {actualSecondsRemaining > 0 && (
                <TimeRemaining>
                  <ClockIcon stroke="white" /> {timeRemainingString}
                </TimeRemaining>
              )}
              {title}
            </div>
          )}

          {inRoom &&
            actualSecondsRemaining != 0 &&
            actualSecondsRemaining <= secondsUntilBarVisible &&
            ((sessionClosingType == "closing" && (
              <FormattedMessage
                id="lobby_status_badge.room_closing_in"
                description="The countdown notification in the lobby status badge when the session is ending."
                defaultMessage="Session ending in {actualSecondsRemaining} seconds"
                values={{ actualSecondsRemaining }}
              />
            )) ||
              (sessionClosingType == "lobby" && (
                <FormattedMessage
                  id="lobby_status_badge.lobby_in"
                  description="The countdown notification in the lobby status badge when the session is returning to the lobby"
                  defaultMessage="Returning to lobby in {actualSecondsRemaining} seconds"
                  values={{ actualSecondsRemaining }}
                />
              )) ||
              (sessionClosingType == "feedback" && (
                <FormattedMessage
                  id="lobby_status_badge.feedback_in"
                  description="The countdown notification in the lobby status badge when the session is skipping to feedback"
                  defaultMessage="Flow ending in {actualSecondsRemaining} seconds"
                  values={{ actualSecondsRemaining }}
                />
              )))}
          {actualSecondsRemaining <= secondsUntilBarVisible && (
            <CountdownBar style={{ width: (actualSecondsRemaining / 20) * 100 + "%" }} />
          )}
        </div>

        <div className="buttons">
          {inRoom ? (
            <LinkButton
              onClick={() => {
                doAction(core, "end_session", -1, -1);
              }}
            >
              <FormattedMessage
                description="Text for the button in the lobby status badge that leaves a breakout room"
                id="lobby_status_badge.leave_room"
                defaultMessage="Back to lobby"
              />
            </LinkButton>
          ) : (
            participants[currentUser.id]?.breakoutRoomHashId && (
              <LinkButton
                className="join"
                onClick={() => {
                  postBreakoutReturn({
                    roomInvitationHashId: participants[currentUser.id].breakoutRoomHashId || "",
                    inqUserId: currentUser.id,
                  });
                }}
              >
                {inRoom ? null : <BreakoutRoomIcon stroke="white" />}
                {inRoom ? (
                  ""
                ) : (
                  <FormattedMessage
                    id="lobby_status_badge.breakout"
                    description="Text for the description half of the button in the lobby status badge that re-joins a breakout room"
                    defaultMessage="Re-join flow"
                  />
                )}
              </LinkButton>
            )
          )}
          <LeaveButton
            style={{
              zIndex: confirmLeaveModalOpen ? 115 : 0,
              backgroundColor: confirmLeaveModalOpen ? "#fff" : "#722",
              borderBottomRightRadius: confirmLeaveModalOpen ? 0 : 8,
              borderBottomLeftRadius: confirmLeaveModalOpen ? 0 : 8,
            }}
            onClick={() => {
              if (confirmLeaveModalOpen) {
                modalClear("ConfirmLeave");
              } else {
                modalShow("ConfirmLeave", true);
              }
            }}
          >
            {confirmLeaveModalOpen ? <Close stroke="#000" width="40px" height="40px" /> : <Leave fill="white" />}
          </LeaveButton>
        </div>
      </Status>
    </Wrapper>
  );
};
