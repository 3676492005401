import React, { FC } from "react";
import { useShallow } from "zustand/react/shallow";
import { useStore } from "../../../core/store";
import { FlowsConfig } from "./FlowsConfig";

export const FlowsModal: FC = () => {
  const { showBreakoutModal, rooms, invitation } = useStore(
    useShallow((state) => ({
      showBreakoutModal: state.showModals.includes("BreakoutModal"),
      participants: state.participants,
      excludedParticipantIds: state.excludedParticipantIds,
      rooms: state.rooms,
      selectedFlow: state.selectedFlow,
      invitation: state.invitation,
    })),
  );

  const lobbyHashId = invitation.lobbyHashId || invitation.hashedID;
  const nonLobbyRooms = rooms.filter((room) => room.hashId !== lobbyHashId);

  if (!showBreakoutModal) return null;

  return <FlowsConfig rooms={rooms} nonLobbyRooms={nonLobbyRooms} invitation={invitation} />;
};
