import styled from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CheckMarkIcon, ExpandIcon, ContractIcon, MicrophoneSlash, VideoSlash } from "../../../assets/icons/Icons";
import { useStore } from "../../../core/store";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import AudioLevel from "../AudioLevel/AudioLevel";
import { ElementPosition } from "../AudioLevel/AudioLevelInner";
import { useShallow } from "zustand/react/shallow";
import { useParticipantProperty } from "@daily-co/daily-react";
import { SelfTile } from "../VideoGrid/SelfTile";
import { RaisedHand } from "../../../assets/icons/Icons";

const Pill = styled.div`
  display: flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  z-index: 10;

  align-items: center;
  min-height: 34px;
  width: auto;
  padding: 0px 8px;
  border-radius: 0px 8px 0px 8px;
`;

export const UsernameWrapper = styled.div`
  display: flex;
  z-index: 5;

  position: absolute;
  bottom: 0px;
  left: 0px;

  &.raisedHand {
    cursor: pointer;
    ${Pill} {
      background: #c51;
    }
  }

  .raisedHandIcon {
    position: relative;
    height: 100%;
    top: -1px;
  }

  &.isLocal {
    right: 10px;
    left: auto;
    bottom: 80px;

    ${Pill} {
      border-radius: 8px;
    }
    @media (max-width: 740px) {
      bottom: 75px;
      &.multipleParticipants {
        bottom: 5px;
        right: 5px;
      }
    }
  }
`;

const Name = styled.div`
  font-size: 14px;
  z-index: 100;
  display: flex;
  flex-align: center;
`;

const CheckMark = styled.div`
  display: flex;
`;

const Expand = styled.button`
  display: flex;
  align-items: center;

  background: rgba(0, 0, 0, 0);
  border: none;
  padding: 0px;
`;

const Contract = styled.button`
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  border: none;
  padding: 0px;
`;

const Username = ({
  name,
  isLocal,
  participantLocation,
  roleData,
  dailyID,
  solo,
  userId,
}: {
  name: string;
  isLocal: boolean;
  participantLocation: Participant["location"];
  roleData: RoleDataItem | undefined;
  dailyID: string;
  solo: boolean;
  userId: number;
}) => {
  const { showSelf, participants, raisedHands, currentUser, core } = useStore(
    useShallow((state) => ({
      showSelf: state.showSelf,
      participants: state.participants,
      raisedHands: state.raisedHands,
      currentUser: state.currentUser,
      core: state.core,
    })),
  );

  const intl = useIntl();

  const trackState = useParticipantProperty(dailyID, "tracks");
  const hasVideo = trackState && trackState.video.state === "playable";
  const hasAudio = trackState && trackState.audio.state === "playable";

  const raisedHand = raisedHands.includes(userId);

  const classNames = [];
  if (isLocal) classNames.push("isLocal");
  if (raisedHand) classNames.push("raisedHand");
  if (Object.values(participants).length > 2) classNames.push("multipleParticipants");

  const lowerHand = () => {
    core.subscription?.perform("toggle_hand", {
      participantId: userId,
      action: "lower_hand",
    });
  };

  return (
    <>
      <UsernameWrapper className={classNames.join(" ")} onClick={lowerHand}>
        {showSelf && isLocal && (
          <SelfTile
            userName={currentUser.name}
            warmspaceID={currentUser.id}
            participantLocation={"session"}
            participantCount={1}
            key={"video-self"}
          />
        )}
        <Pill>
          {raisedHand && <RaisedHand stroke="#fff" width={16} />}
          <Name>
            {isLocal ? (
              <FormattedMessage
                description="Text indicating self username is You"
                id="username.you"
                defaultMessage="You"
              />
            ) : (
              name
            )}
          </Name>

          {!hasAudio && participantLocation === "session" ? (
            <MicrophoneSlash />
          ) : dailyID ? (
            <AudioLevel id={dailyID} local={isLocal} position={ElementPosition.TileViewV2} />
          ) : null}

          {!hasVideo && participantLocation === "session" ? <VideoSlash /> : ""}

          {roleData && roleData.actionStatus !== "NONE" && participantLocation === "session" && (
            <>
              <CheckMark
                data-tooltip-id={"my-tooltip-" + name}
                data-tooltip-content={
                  roleData.actionStatus == "COMPLETED"
                    ? intl.formatMessage(
                        {
                          description: "Username actionstatus step completed by user",
                          id: "username.has_completed_step",
                          defaultMessage: "{name} has completed this step",
                        },
                        { name: name },
                      )
                    : intl.formatMessage(
                        {
                          description: "Username actionstatus Step not yet completed by user",
                          id: "username.has_not_completed_step",
                          defaultMessage: "{name} has not completed this step yet",
                        },
                        { name: name },
                      )
                }
              >
                <CheckMarkIcon stroke={roleData.actionStatus == "COMPLETED" ? "#E7E7E7" : "grey"} />
              </CheckMark>
              <Tooltip id={"my-tooltip-" + name} />
            </>
          )}

          {isLocal &&
            !solo &&
            (showSelf ? (
              <Contract
                onClick={() => {
                  useStore.setState({ showSelf: false });
                }}
                data-testid="contract-icon"
              >
                <ContractIcon fill="white" />
              </Contract>
            ) : (
              <Expand
                onClick={() => {
                  useStore.setState({ showSelf: true });
                }}
                data-testid="expand-icon"
              >
                <ExpandIcon />
              </Expand>
            ))}
        </Pill>
      </UsernameWrapper>
    </>
  );
};

export default Username;
