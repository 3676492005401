import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { VideoGridTileContainer } from "./VideoGridTile";
import { RecordingBadge } from "../Badge/RecordingBadge";
import { useDaily, useScreenShare } from "@daily-co/daily-react";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { VideoGridMobile } from "./VideoGridMobile";
import { VideoGridDesktopOneOnOne } from "./VideoGridDesktopOneOnOne";
import { Content } from "./Content";
import { ButtonArea } from "./ButtonArea";
import { PaginationControls } from "./PaginationControls";
import { VideoGridDesktop } from "./VideoGridDesktop";
import { PARTICIPANTS_PER_PAGE } from "../../../lib/defaults";
import TileAudio from "./TileAudio";

export interface VideoGridProps {
  recordOption: "recording" | "transcribing" | null;
  participants: Participants;
  showUI: boolean;
  buttonAreaContent?: ReactNode;
  contentAreaContent?: ReactNode;
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Wrapper = styled.div`
  height: calc(100% - 60px); // Space for the menu bar
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &.noContentArea {
    justify-content: flex-start;
  }
`;

const VideoGridContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  transition:
    height 0.3s ease,
    transform 0.3s ease;
  position: relative;
  min-height: calc(100% - 130px);

  &.screensharing {
    display: flex;

    flex-direction: row;
    align-items: center;

    ${VideoGridTileContainer} {
      video {
        object-fit: cover !important;
      }
    }
  }

  &.small {
    display: flex;
    flex-direction: column;

    ${PaginationControls} {
      margin-top: 5px;
    }
  }

  &.medium,
  &.large {
    height: 100%;
    width: 100%;
    margin-bottom: 5px;
    box-sizing: border-box;

    ${PaginationControls} {
      .pageIndicator {
        width: 10px;
        height: 10px;
      }
    }

    ${VideoGridTileContainer} {
      box-sizing: border-box;
    }

    &.participants-2ormore.noButtonArea {
      height: 100%;
    }

    &.participants-2ormore {
      height: calc(100% - 60px);
      ${VideoGridTileContainer} {
        border: 2.5px solid #191919;
      }
    }

    &.participants-1 {
      ${VideoGridTileContainer} {
        video {
          object-fit: contain;
        }
      }
    }
  }

  &.small {
    &.showUI {
      height: calc(100% - 60px);

      &.noButtonArea {
        height: 100%;
      }
    }
  }

  .fullscreen {
    height: 100%;
    width: 100%;
  }
`;

export const VideoGrid: FC<VideoGridProps> = ({
  recordOption,
  participants,
  showUI,
  buttonAreaContent,
  contentAreaContent,
  onMouseMove,
}) => {
  console.debug("[session-ui]: VideoGrid render");
  const [page, setPage] = useState(0);

  const {
    showSelf,
    roleData,
    deviceClassification,
    particpantsLastActiveDates,
    narrating,
    showExternalContent,
    showBreakoutModal,
    showChat,
    currentUser,
    invitation,
  } = useStore(
    useShallow((state) => ({
      showSelf: state.showSelf,
      roleData: state.roleData,
      deviceClassification: state.deviceClassification,
      particpantsLastActiveDates: state.participantsLastActiveDates,
      narrating: state.narrating,
      showExternalContent: state.showExternalContent,
      showBreakoutModal: state.showModals.includes("BreakoutModal"),
      showChat: state.showChat,
      currentUser: state.currentUser,
      invitation: state.invitation,
    })),
  );

  const { screens } = useScreenShare();
  const callObject = useDaily();

  // Filter out the current user from the participants list
  const allParticipants = Object.values(participants);
  const soloViewMode = allParticipants.length == 1 && (invitation.solo || invitation.muteAll);
  const filteredParticipants = allParticipants.filter((x) => x.id !== currentUser.id || soloViewMode) as Participant[];

  // Sort participants by lastActiveDate
  filteredParticipants.sort((a, b) => {
    const aLastActiveDate = particpantsLastActiveDates[a.id] || 0;
    const bLastActiveDate = particpantsLastActiveDates[b.id] || 0;

    if (aLastActiveDate > bLastActiveDate) return -1;
    if (bLastActiveDate < aLastActiveDate) return 1;
    return 0;
  });

  // Calculate classes. These are applied to various elements and are used to control the layout
  // depending on the number of participants, the size of the device, etc.
  const classes = [];
  classes.push("participants-" + filteredParticipants.length);
  classes.push(deviceClassification.size);
  if (showUI) classes.push("showUI");
  if (filteredParticipants.length >= 2) classes.push("participants-2ormore");
  if (filteredParticipants.length >= 4) classes.push("participants-4ormore");
  if (!buttonAreaContent) classes.push("noButtonArea");
  if (!contentAreaContent) classes.push("noContentArea");
  if (recordOption) classes.push("record");
  const showScreenOrContent = screens.length > 0 || showExternalContent;
  if (showScreenOrContent) classes.push("screensharing");
  if (showBreakoutModal) classes.push("modalVisible");
  if (showChat) classes.push("chatVisible");

  // This is the callback that is called when the intersection observer detects a change in the
  // visibility of a video tile.
  // It is used to subscribe to the video of the participant when the tile is visible and unsubscribe
  // when the tile is not visible.
  const intersectionObserverCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        // @ts-ignore
        const id = entry.target.dataset.id;

        if (!id) return;

        if (entry.isIntersecting) {
          document.getElementById("paginationDotFor-" + id)?.classList.add("active");
          entry.target.classList.add("active");

          callObject?.updateParticipant(id, {
            setSubscribedTracks: { video: true, audio: true, screenVideo: true, screenAudio: true },
          });
        } else {
          document.getElementById("paginationDotFor-" + id)?.classList.remove("active");
          entry.target.classList.remove("active");

          callObject?.updateParticipant(id, {
            setSubscribedTracks: { video: "staged", audio: true, screenVideo: true, screenAudio: true },
          });
        }
      });
    },
    [callObject],
  );

  useEffect(() => {
    // Dispatch a resize event when something changes how much space the VideoGrid has so
    // PackedGrid can recalculate the layout.
    window.dispatchEvent(new Event("resize"));
  }, [showExternalContent, showBreakoutModal, showChat]);

  // Determine visible video blocks to subscribe to.
  useEffect(() => {
    if (deviceClassification.size !== "small") {
      // We fire this resize event so that the PackedGrid correctly adjusts
      // itself too when the pagination controls appear.
      window.dispatchEvent(new Event("resize"));

      if (!callObject) return;

      filteredParticipants.forEach((p, index) => {
        if (index >= page * PARTICIPANTS_PER_PAGE && index < (page + 1) * PARTICIPANTS_PER_PAGE) {
          // Subscribe to the video of the participants that are visible in the current page.
          callObject.updateParticipant(p.dailySessionID, {
            setSubscribedTracks: { video: true, audio: true, screenVideo: true, screenAudio: true },
          });
        } else if (index >= (page + 1) * PARTICIPANTS_PER_PAGE && index < (page + 2) * PARTICIPANTS_PER_PAGE) {
          // Stage the video of the participants that are on the next page.
          callObject.updateParticipant(p.dailySessionID, {
            setSubscribedTracks: { video: "staged", audio: true, screenVideo: true, screenAudio: true },
          });
        } else if (page > 0 && index >= (page - 1) * PARTICIPANTS_PER_PAGE && index < page * PARTICIPANTS_PER_PAGE) {
          // Stage the video of the participants that are on the previous page.
          callObject.updateParticipant(p.dailySessionID, {
            setSubscribedTracks: { video: "staged", audio: true, screenVideo: true, screenAudio: true },
          });
        } else {
          // Unsubscribe the video of any other participants.
          callObject.updateParticipant(p.dailySessionID, {
            setSubscribedTracks: { video: false, audio: true, screenVideo: true, screenAudio: true },
          });
        }
      });

      return;
    }

    const observer = new IntersectionObserver(intersectionObserverCallback, {
      rootMargin: "0px",
      threshold: 0.1,
    });

    const tiles = document.getElementsByClassName("videoGridTile");

    Array.prototype.forEach.call(tiles, (tile) => {
      observer.observe(tile);
    });

    return () => {
      observer?.disconnect();
    };
  }, [
    participants,
    showExternalContent,
    particpantsLastActiveDates,
    intersectionObserverCallback,
    deviceClassification.size,
    filteredParticipants.length,
    screens.length,
    page,
  ]);

  return (
    <Wrapper className={classes.join(" ")} onMouseMove={onMouseMove} onClick={onMouseMove}>
      {contentAreaContent && <Content className={classes.join(" ")}>{contentAreaContent}</Content>}

      <VideoGridContainer className={classes.join(" ")}>
        {recordOption && <RecordingBadge text={recordOption == "recording" ? "Recording" : "Transcribing"} />}

        {deviceClassification.size === "small" && (
          <VideoGridMobile
            filteredParticipants={filteredParticipants}
            screens={screens}
            classes={classes}
            showScreenOrContent={showScreenOrContent}
          />
        )}

        {deviceClassification.size !== "small" && filteredParticipants.length === 1 && !showScreenOrContent && (
          <VideoGridDesktopOneOnOne filteredParticipants={filteredParticipants} />
        )}

        {deviceClassification.size !== "small" && (filteredParticipants.length > 1 || showScreenOrContent) && (
          <VideoGridDesktop
            filteredParticipants={filteredParticipants}
            screens={screens}
            showSelf={!soloViewMode && showSelf}
            currentUser={currentUser}
            roleData={roleData}
            page={page}
            setPage={setPage}
          />
        )}

        {!narrating &&
          !invitation.solo &&
          filteredParticipants.map((p) => {
            return (
              p.dailySessionID && <TileAudio key={"audio-" + p.dailySessionID} id={p.dailySessionID} isLocal={false} />
            );
          })}

        {screens.map((screen) => {
          if (!screen.local) {
            return (
              <TileAudio key={"audio-" + screen.screenId} id={screen.session_id} isScreen={true} isLocal={false} />
            );
          } else {
            return null;
          }
        })}
      </VideoGridContainer>
      <ButtonArea className={classes.join(" ")}>{buttonAreaContent}</ButtonArea>
    </Wrapper>
  );
};
