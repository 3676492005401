import React, { FC } from "react";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import styled from "styled-components";
import { isContentStep, isFeedbackScoreStep, isFeedbackWordsStep } from "../../../types/type_guards";
import { secondsToMMSS } from "../../../lib/duration";

interface Props {}

const Wrapper = styled.div`
  font-size: 30px;
  margin-top: 30px;
  font-weight: bold;

  display: flex;
  justify-content: center;

  &.smallText {
    font-size: 20px;
  }

  span {
    position: relative;

    display: flex;
    width: 100px;
    height: 100px;

    div {
      position: absolute;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  svg {
    transform: rotate(-90deg);
  }

  svg circle {
    stroke-dasharray: 300px;

    stroke-linecap: round;
    stroke-width: 4px;
    fill: none;
    stroke: #fe5f25;
    z-index: 1;

    stroke-dashoffset: 0px;

    transition: stroke-dashoffset 1s linear;
  }

  svg circle.track {
    stroke: #d9cdb7;
    z-index: -1;
  }
`;

export const InPersonStepCountdown: FC<Props> = () => {
  const { timeOnStep, currentStep, stepTiming, invitation } = useStore(
    useShallow((state) => ({
      roleData: state.roleData,
      currentStep: state.currentStep,
      timeOnStep: state.timeOnStep,
      currentUser: state.currentUser,
      stepTiming: state.stepTiming,
      invitation: state.invitation,
    })),
  );

  if (
    invitation.lobby ||
    (!isContentStep(currentStep) && !isFeedbackScoreStep(currentStep) && !isFeedbackWordsStep(currentStep))
  ) {
    return null;
  }

  const timeRemainingOnStep = Math.max(stepTiming - timeOnStep, 0);

  const percentage = timeRemainingOnStep / stepTiming;
  const dashoffset = 300 - percentage * 300;

  if (!stepTiming || Number.isNaN(timeRemainingOnStep)) {
    return null;
  }

  return (
    <Wrapper className={timeRemainingOnStep > 60 ? "smallText" : ""}>
      <span>
        <div>{timeRemainingOnStep > 60 ? secondsToMMSS(timeRemainingOnStep) : timeRemainingOnStep}</div>
        <svg width="100%" height="100%">
          <circle
            className="track"
            data-testid="track-circle"
            r="40"
            cx="50%"
            cy="50%"
            style={{ strokeDashoffset: "0px" }}
          ></circle>
          <circle
            data-testid="countdown-circle"
            r="40"
            cx="50%"
            cy="50%"
            style={{ strokeDashoffset: dashoffset }}
          ></circle>
        </svg>
      </span>
    </Wrapper>
  );
};
