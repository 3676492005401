import React from "react";
import ErrorMessage from "../ErrorMessages/ErrorMessage";
import { Button } from "../ButtonV2/Button";
import { ReportPartnerIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";

export const EndScreen = () => {
  const { invitation, inPerson } = useStore(
    useShallow((state) => ({ invitation: state.invitation, inPerson: state.inPerson })),
  );

  return (
    <ErrorMessage
      heading="Thank you for using Warmspace"
      lowerArea={
        !inPerson &&
        invitation.enableReportPartner && (
          <div>
            <Button
              className="secondary "
              onClick={() => {
                useStore.setState({ showModals: ["ReportPartner"] });
              }}
            >
              <ReportPartnerIcon />
              <FormattedMessage
                description="Button text to leave the call"
                id="confirm_leave_modal.report_partner"
                defaultMessage="Report Partner"
              />
            </Button>
          </div>
        )
      }
    >
      <FormattedMessage
        defaultMessage="You may now close this window."
        description="End of session message"
        id="app.end_of_session_message"
      />
      <br />
      <br />
      <FormattedMessage
        id="app.join_message"
        defaultMessage="Or {link} "
        values={{
          link: (
            <>
              <a href="">
                <FormattedMessage id="app.join_link" defaultMessage="join the call again" />
              </a>
              <br />
            </>
          ),
        }}
      />
    </ErrorMessage>
  );
};
