import React, { FC, useState } from "react";
import toast from "react-simple-toasts";

import { FormattedMessage } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Close, Spinner } from "../../../assets/icons/Icons";

import { Overlay, Card, CardHeader, CardFooter } from "../Modal/ModalStyle";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import postBreakoutClose from "../../../lib/requests/postBreakoutClose";
import { modalClear } from "../Modal/Modals";

export const ConfirmCloseRoomModal: FC = () => {
  const { selectedRoom, invitation } = useStore(
    useShallow((state) => ({
      selectedRoom: state.selectedRoom,
      invitation: state.invitation,
    })),
  );

  const [loadingClose, setLoadingClose] = useState<boolean>(false);

  const handleCloseModal = () => {
    useStore.setState({
      selectedRoom: null,
    });
    modalClear("ConfirmCloseBreakout");
  };

  // Closes a room or all rooms depending on roomHashId.
  // If roomHashId is null, all rooms are closed.
  const handleCloseRoom = () => {
    return async () => {
      const roomToClose = selectedRoom == "allRooms" ? null : selectedRoom;
      setLoadingClose(true);

      try {
        await postBreakoutClose({
          lobbyInvitationHashId: invitation.hashedID,
          roomInvitationHashId: roomToClose,
        });
        handleCloseModal();
      } catch (e) {
        console.error(e);
        toast("Unable to close break out room. Please try again in a few minutes.");
      } finally {
        setLoadingClose(false);
      }
    };
  };

  return (
    <>
      <Card>
        <CardHeader>
          {selectedRoom == "allRooms" ? (
            <FormattedMessage
              description="Header text for the confirm close all breakout rooms modal"
              id="confirm_breakout_close_all_modal.heading"
              defaultMessage={"Close All Rooms?"}
            />
          ) : (
            <FormattedMessage
              description="Header text for the confirm close breakout room modal"
              id="confirm_breakout_close_modal.heading"
              defaultMessage={"Close Room?"}
            />
          )}

          <Button className="link" onClick={handleCloseModal}>
            <Close />
          </Button>
        </CardHeader>

        <CardFooter>
          <Button onClick={handleCloseModal} className="link" style={{ color: "black" }}>
            <FormattedMessage
              description="Button text to cancel closing a breakout room"
              id="confirm_breakout_close_modal.cancel"
              defaultMessage={"Cancel"}
            />
          </Button>

          {loadingClose ? (
            <Spinner />
          ) : (
            <Button style={{ width: "auto", fontSize: "16px", padding: "8px 15px" }} onClick={handleCloseRoom()}>
              {selectedRoom == "allRooms" ? (
                <FormattedMessage
                  description="Button text confirming to close all breakout rooms"
                  id="confirm_breakout_close_all_modal.confirm_button"
                  defaultMessage={"Close All Rooms"}
                />
              ) : (
                <FormattedMessage
                  description="Button text confirming to close the breakout room"
                  id="confirm_breakout_close_modal.confirm_button"
                  defaultMessage={"Close Room"}
                />
              )}
            </Button>
          )}
        </CardFooter>
      </Card>
      <Overlay onClick={handleCloseModal} />
    </>
  );
};
