import React, { FC, useCallback, useEffect, useState } from "react";
import { PageToggle } from "./PageToggle";
import { FormattedMessage, useIntl } from "react-intl";
import { BigButton, ConfigButton, ConfigButtons, RoomsPage } from "./RoomsPage";
import { StartNewPage, obtainFilteredParticipants } from "./StartNewPage";
import { BreakoutRoomMessage } from "../../../types/CableMessage";
import { CardBody, CardHeader, NarrowCard, TextHeader } from "../Modal/ModalStyle";
import { Button } from "../ButtonV2/Button";
import { Close, Participants, Settings } from "../../../assets/icons/Icons";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { modalClear, modalShow } from "../Modal/Modals";
import { LobbyPage } from "./LobbyPage";
import styled from "styled-components";
import { FlowItem } from "./FlowItem";
import { Well } from "../Well/Well";
import { MatchingCheckBox } from "./MatchingCheckBox";
import postBreakout from "../../../lib/requests/postBreakout";
import toast from "react-simple-toasts";
import { GroupSizeConfig } from "./RoomsConfig/GroupSizeConfig";
import { AutoJoinConfig } from "./RoomsConfig/AutoJoinConfig";
import { PromptsConfig } from "./RoomsConfig/PromptsConfig";
import postTimeEstimate from "../../../lib/requests/postTimeEstimate";
import { FlowDurationConfig } from "./RoomsConfig/FlowDurationConfig";

interface Props {
  rooms: BreakoutRoomMessage[];
  nonLobbyRooms: BreakoutRoomMessage[];

  invitation: InvitationResponse;
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 4px;
  gap: 5px;
`;

const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  background-color: #fff;
  border-radius: 9px;
  padding: 10px;
  overflow-y: scroll;
  margin-top: -8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const FlowsConfig: FC<Props> = ({ rooms, nonLobbyRooms, invitation }) => {
  const intl = useIntl();

  const roomsLabel = intl.formatMessage(
    {
      id: "breakout_modal.rooms",
      defaultMessage: "Rooms {roomsCount}",
    },
    { roomsCount: rooms.length > 0 ? `(${nonLobbyRooms.length})` : "" },
  );

  const lobbyLabel = intl.formatMessage({
    id: "breakout_modal.lobby",
    defaultMessage: "Lobby",
  });

  const startNewLabel = intl.formatMessage({
    id: "breakout_modal.start_new",
    defaultMessage: "Start New",
  });

  const [page, setPage] = useState<string>(startNewLabel);

  const pages = [startNewLabel, lobbyLabel, roomsLabel];

  // It's possible the rooms label has changed due to a different number of rooms, so the label for the 'pages' tab needs to be updated
  if (!pages.includes(page)) {
    setPage(roomsLabel);
  }

  const { selectedFlow, currentFlowConfig, participants, excludedParticipantIds, showRoomsConfig } = useStore(
    useShallow((state) => ({
      selectedFlow: state.selectedFlow,
      currentFlowConfig: state.currentFlowConfig,
      participants: state.participants,
      excludedParticipantIds: state.excludedParticipantIds,
      showRoomsConfig: state.showModals.includes("RoomsConfig"),
    })),
  );
  const [loading, setLoading] = React.useState(false);

  // Only include the participants that made it into the session
  const lobbyParticipants = Object.values(participants);

  const { inSessionParticipants } = obtainFilteredParticipants(lobbyParticipants, excludedParticipantIds);

  const [prevSelectedFlow, setPrevSelectedFlow] = React.useState(selectedFlow || undefined);

  // If the config values weren't previously initialized or the selected flow has changed, then set them now.
  if (selectedFlow && prevSelectedFlow != selectedFlow) {
    setPrevSelectedFlow(selectedFlow);
  }

  const [minParticipants, setMinParticipants] = React.useState(selectedFlow?.min);
  const [maxParticipants, setMaxParticipants] = React.useState(selectedFlow?.max);
  const [prefParticipants, setPrefParticipants] = React.useState(selectedFlow?.preferred);
  const [timeEstimateTimeout, setTimeEstimateTimeout] = React.useState<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (currentFlowConfig) {
      if (prefParticipants != currentFlowConfig?.preferred) setPrefParticipants(currentFlowConfig.preferred);
      if (minParticipants != currentFlowConfig?.min) setMinParticipants(currentFlowConfig.min);
      if (maxParticipants != currentFlowConfig?.max) setMaxParticipants(currentFlowConfig.max);
    }
  }, [currentFlowConfig]);

  useEffect(() => {
    if (currentFlowConfig) {
      const { inSessionParticipants, allExcludedParticipantIds } = obtainFilteredParticipants(
        lobbyParticipants,
        excludedParticipantIds,
      );
      const estimateRequest = {
        flowHashId: currentFlowConfig!.hashId,
        min: currentFlowConfig!.min,
        max: currentFlowConfig!.max,
        pref: currentFlowConfig!.preferred,
        numParticipants: inSessionParticipants.length - allExcludedParticipantIds.length,
      };

      // Wait 150 ms before making the call in case the inputs are actively changing
      if (timeEstimateTimeout) {
        clearTimeout(timeEstimateTimeout);
      }
      setTimeEstimateTimeout(
        setTimeout(() => {
          const promise = postTimeEstimate(estimateRequest);

          promise.then((result) => {
            const newFlowConfig = Object.assign({}, currentFlowConfig);
            newFlowConfig.minDuration = result.minDuration;
            newFlowConfig.maxDuration = result.maxDuration;
            newFlowConfig.minGroupSize = result.minGroupSize;
            newFlowConfig.maxGroupSize = result.maxGroupSize;
            newFlowConfig.numUnmatchedParticipants = result.numUnmatchedParticipants;
            useStore.setState({ currentFlowConfig: newFlowConfig });
          });
        }, 150),
      );
    }
  }, [prefParticipants, minParticipants, maxParticipants, participants, excludedParticipantIds]);

  const handleStartRoom = useCallback(async () => {
    if (selectedFlow === null) return;

    setLoading(true);

    const activeFlowConfig = useStore.getState().currentFlowConfig || selectedFlow;

    useStore.setState({
      selectedFlow,
      showMatchingConfig: false,
    });

    let promptsChanged = false;
    if (activeFlowConfig != null && activeFlowConfig != selectedFlow) {
      for (let i = 0; i < activeFlowConfig.prompts.length; i++) {
        if (activeFlowConfig.prompts[i] != selectedFlow.prompts[i]) {
          promptsChanged = true;
        }
      }
    }

    const { allExcludedParticipantIds } = obtainFilteredParticipants(
      Object.values(participants),
      excludedParticipantIds,
    );

    try {
      const state = useStore.getState();

      await postBreakout({
        lobbyInvitationHashId: invitation.lobbyHashId,
        breakoutInvitationHashId: selectedFlow!.hashId,
        excludeUserIds: allExcludedParticipantIds,
        minParticipants: activeFlowConfig!.min,
        maxParticipants: activeFlowConfig!.max,
        preferredParticipants: activeFlowConfig!.preferred,
        meetingLocation: state.inPerson ? "in_person" : "online",
        duration: activeFlowConfig!.duration, // 0 means don't enforce duration
        autoJoin: state.autoJoin,
        prompts: promptsChanged ? activeFlowConfig!.prompts : null, // null means don't override the prompts
        durationPreference: activeFlowConfig!.durationPreference,
      });
      useStore.setState({ showModals: [] });
    } catch (e) {
      toast("Unable to start flow. Please try again in a few minutes.");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [selectedFlow, excludedParticipantIds, participants]);

  return (
    <>
      <NarrowCard className="breakout-modal">
        <CardHeader className="fullscreen">
          <TextHeader>
            <FormattedMessage description="Breakout header title" id="breakout_modal.title" defaultMessage={"Flows"} />
          </TextHeader>
          <Button
            className="link"
            style={{
              position: "absolute",
              right: "15px",
            }}
            onClick={() => {
              modalClear("BreakoutModal");
            }}
          >
            <Close />
          </Button>
        </CardHeader>
        <CardBody className="fullscreen">
          <PageToggle
            pages={pages}
            setPage={(page) => {
              setPage(page);
              modalClear("AddParticipantToRoom");
            }}
            activePage={page}
          />

          {page === startNewLabel && !selectedFlow && <StartNewPage flows={invitation.lobbyInvitations} />}

          {page === startNewLabel && selectedFlow && (
            <>
              <Well>
                <FlowItem
                  key={selectedFlow?.hashId}
                  flow={currentFlowConfig || selectedFlow!}
                  data-testid={`flow-${selectedFlow?.hashId}`}
                  mode="back"
                />
              </Well>
              {!showRoomsConfig && <MatchingCheckBox participants={inSessionParticipants} />}

              {showRoomsConfig && (
                <ConfigWrapper>
                  <GroupSizeConfig />
                  <FlowDurationConfig />
                  <AutoJoinConfig />
                  <PromptsConfig />
                </ConfigWrapper>
              )}

              <ButtonWrapper>
                <ConfigButtons>
                  <ConfigButton
                    onClick={() => {
                      modalClear("RoomsConfig");
                    }}
                    className={!showRoomsConfig ? "selected" : ""}
                  >
                    <Participants />
                  </ConfigButton>
                  <ConfigButton
                    className={showRoomsConfig ? "selected" : ""}
                    onClick={() => {
                      modalShow("RoomsConfig", false);
                    }}
                  >
                    <Settings width="24px" height="24px" />
                  </ConfigButton>
                </ConfigButtons>
                <BigButton
                  disabled={
                    inSessionParticipants.length - excludedParticipantIds.length < (currentFlowConfig?.min || 0)
                  }
                  loading={loading}
                  onClick={handleStartRoom}
                >
                  <FormattedMessage
                    description="Button text confirming to start the breakout"
                    id="confirm_breakout_modal.confirm_button"
                    defaultMessage="Start Flow"
                  />
                </BigButton>
              </ButtonWrapper>
            </>
          )}

          {page === lobbyLabel && <LobbyPage rooms={nonLobbyRooms} participants={lobbyParticipants} />}
          {page === roomsLabel && <RoomsPage rooms={nonLobbyRooms} inSessionParticipants={inSessionParticipants} />}
        </CardBody>
      </NarrowCard>
    </>
  );
};
