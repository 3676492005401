import { useEffect, useState } from "react";
import { toggleFullscreen, handleFullscreen, applyDocumentHandlers } from "../lib/fullscreen";

export function useFullscreenHandler() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    applyDocumentHandlers(handleFullscreen.bind(undefined, setIsFullscreen));
  }, []);

  return { toggleFullscreen: toggleFullscreen.bind(null, setIsFullscreen), isFullscreen };
}
