export const SFX: {
  loadAudios: () => void;
  timeChime?: HTMLAudioElement;
  newChatMessage?: HTMLAudioElement;
  partnerDisconnected?: HTMLAudioElement;
  partnerArrived?: HTMLAudioElement;
  recordingStarted?: HTMLAudioElement;
} = {
  loadAudios: () => {
    console.debug("[session-ui]: Loading sound effects files.");
    SFX.timeChime = new Audio(
      "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-30-07/sfx/sfx-time-chime.mp3",
    );

    SFX.newChatMessage = new Audio(
      "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-30-07/sfx/sfx-chat-ping.mp3",
    );

    SFX.partnerDisconnected = new Audio(
      "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-11-23/sfx/sfx-disconnect.mp3",
    );

    SFX.partnerArrived = new Audio(
      "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-11-23/sfx/sfx-arrive.mp3",
    );

    SFX.recordingStarted = new Audio(
      "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2024-09-08/sfx/sfx-recording-started.mp3",
    );

    SFX.partnerArrived.crossOrigin = "anonymous";
    SFX.partnerDisconnected.crossOrigin = "anonymous";
    SFX.newChatMessage.crossOrigin = "anonymous";
    SFX.timeChime.crossOrigin = "anonymous";
    SFX.recordingStarted.crossOrigin = "anonymous";
  },
};
