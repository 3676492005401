import { textForLocale } from "../lib/textForLocale";
import {
  isChoicesStep,
  isContentStep,
  isIframeStep,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
} from "../types/type_guards";
import shuffle from "../lib/shuffle";
import { Liquid } from "liquidjs";
import { Locales } from "./intl";
import { IntlShape } from "react-intl";

const liquidEngine = new Liquid({ strictFilters: true, strictVariables: true });
liquidEngine.registerFilter("shuffle", (a, seed = 1) => shuffle(a, seed));

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatNames = (names: string[], intl: IntlShape): string => {
  switch (names.length) {
    case 0:
      return intl.formatMessage({
        description: "Format names text for yourself",
        id: "determine_content.yourself",
        defaultMessage: "yourself",
      });
    case 1:
      return names[0];
    case 2:
      return `${names[0]} ${intl.formatMessage({
        description: "Format names text for returning multiple names using and",
        id: "determine_content.and",
        defaultMessage: "and",
      })} ${names[1]}`;
    case 3:
    case 4:
    case 5:
      return `${names.slice(0, names.length - 1).join(", ")} ${intl.formatMessage({
        description: "Format names text for returning multiple names using and",
        id: "determine_content.and",
        defaultMessage: "and",
      })} ${names[names.length - 1]}`;
    default:
      return intl.formatMessage({
        description: "Format names text when case is default",
        id: "determine_content.your_partners",
        defaultMessage: "your partners",
      });
  }
};

export const determineContent = (
  step: AnyStep,
  userResponses: UserResponses,
  roleData: RoleData,
  currentUserId: number,
  intl: IntlShape,
  locale: Locales,
  flowData: InvitationResponse["flowData"],
  hashedID: string,
): StepContent | undefined => {
  if (
    !(
      isContentStep(step) ||
      isChoicesStep(step) ||
      isIframeStep(step) ||
      isFeedbackScoreStep(step) ||
      isFeedbackWordsStep(step)
    )
  )
    return;

  const role = step.shareContent ? "EVERYONE_ELSE" : roleData[currentUserId]?.role;

  const stepContent = step.content[role];

  const partnerNames = Object.values(roleData)
    .filter((u) => u.id !== currentUserId)
    .map((u) => u.name);

  const formattedNames = formatNames(partnerNames, intl);

  const data = Object.assign(
    {},
    (flowData && (flowData[locale] || flowData["en"])) || {},
    { userResponses },
    {
      aName: Object.values(roleData).find((u) => u.role === "A")?.name,
      bName: Object.values(roleData).find((u) => u.role === "B")?.name,
      allPartners: partnerNames.join(", "),
      yourPartners: formattedNames,
      YourPartners: capitalizeFirstLetter(formattedNames),
      yourName: roleData[currentUserId]?.name,
      gatheringID: hashedID,
    },
  );

  // Text
  const textTemplate = textForLocale(stepContent?.text, locale);
  let text = "";
  try {
    if (textTemplate) {
      text = liquidEngine.parseAndRenderSync(textTemplate, { data });
    }
  } catch (error) {
    // Show the original text if there is an error parsing the liquid template
    // but log the liquid parse error to the console.
    text = textTemplate;

    console.error(error);
  }

  // IFrame Step
  if (isIframeStep(step)) {
    text = intl.formatMessage({
      description: "Determine Content iframe text preparing next step",
      id: "determine_content.iframe_default_message",
      defaultMessage: "Preparing next step...",
    });
  }

  // Button Text
  const actions = stepContent?.actions?.map((action) => {
    const actionText = textForLocale(action.buttonText, locale);
    const actionTextResult = liquidEngine.parseAndRenderSync(actionText, { data });

    return {
      onClick: action.onClick,
      text: actionTextResult,
    };
  });

  // Choices
  let choices: { text: string; onClick: ActionConsequence }[] = [];
  if (isChoicesStep(step)) {
    choices = step.content[role].choices?.map((choice) => {
      const choiceText = textForLocale(choice.text, locale);
      const choiceTextResult = liquidEngine.parseAndRenderSync(choiceText || "", { data });

      return {
        text: choiceTextResult,
        onClick: choice.onClick,
      };
    });
  }

  // Feedback Score
  let descriptionLow = "";
  let descriptionHigh = "";
  if (isFeedbackScoreStep(step)) {
    const template = textForLocale(step.content[role].descriptionLow, locale);
    const highTemplate = textForLocale(step.content[role].descriptionHigh, locale);
    descriptionLow = liquidEngine.parseAndRenderSync(template, { data });
    descriptionHigh = liquidEngine.parseAndRenderSync(highTemplate, { data });
  }

  // Feedback Words
  let placeholder = "";
  if (isFeedbackWordsStep(step)) {
    const placeholderTemplate = textForLocale(step.content[role].placeholder, locale);
    placeholder = liquidEngine.parseAndRenderSync(placeholderTemplate, { data });
  }

  return {
    text,
    actions,
    choices,
    actionTiming: stepContent?.actionTiming,
    descriptionLow,
    descriptionHigh,
    placeholder,
  };
};
