import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Overlay, Card, CardHeader, CardFooter, CardBody } from "../Modal/ModalStyle";
import { useStore } from "../../../core/store";
import { modalClear } from "../Modal/Modals";
import { useDaily } from "@daily-co/daily-react";
import { useShallow } from "zustand/react/shallow";
import { Close, ReportPartnerIcon } from "../../../assets/icons/Icons";

export const ReportPartnerModal: FC = () => {
  const callObject = useDaily();

  const { core } = useStore(
    useShallow((state) => ({
      core: state.core,
    })),
  );

  const handleCloseModal = () => {
    modalClear("ReportPartner");
  };

  return (
    <>
      <Card>
        <CardHeader>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ReportPartnerIcon fill={"#000"} width="20" />
            <b>
              &nbsp;
              <FormattedMessage id="report_partner_modal.header" defaultMessage={"Report Partner"} />
            </b>
          </div>
          <Button
            className="link"
            onClick={() => {
              handleCloseModal();
            }}
          >
            <Close />
          </Button>
        </CardHeader>
        <CardBody>
          <FormattedMessage
            id="report_partner_modal.body_1"
            defaultMessage={"Would you like to report someone in this session for inappropriate behavior?"}
          />
          <br />
          <br />
          <FormattedMessage
            id="report_partner_modal.body_2"
            defaultMessage={
              "If there were multiple people in this session, we may follow up with you for more information."
            }
          />
          <br />
          <br />
        </CardBody>
        <CardFooter>
          <Button
            className="link"
            style={{ color: "#000" }}
            onClick={() => {
              handleCloseModal();
            }}
          >
            <FormattedMessage id="report_partner_modal.cancel" defaultMessage={"Cancel"} />
          </Button>
          <Button
            className="small"
            onClick={() => {
              useStore.setState({ mode: "reportPartner" });
              callObject?.leave();
              core.subscription?.unsubscribe();
              handleCloseModal();
            }}
          >
            <FormattedMessage id="report_partner_modal.report_partner" defaultMessage={"Report Partner"} />
          </Button>
        </CardFooter>
      </Card>
      <Overlay onClick={handleCloseModal} blur={true} />
    </>
  );
};
