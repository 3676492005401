import { Core } from "../core";
import { useStore } from "../store";
import { modalClear } from "../../components/UI/Modal/Modals";

type RecordingAction =
  | "start_recording"
  | "stop_recording"
  | "start_transcription"
  | "start_recording_and_transcription";

export const toggleRecording = function (action: RecordingAction): void {
  useStore.setState({ recordingLoading: true });
  modalClear("RecordingMenu");
  const core = Core.GetInstance();
  core.subscription?.perform("toggle_recording", { action: action });
};
