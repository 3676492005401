import axios from "axios";

interface PostBreakoutCloseRequest {
  lobbyInvitationHashId: string;
  roomInvitationHashId: string | null;
}

export default async (args: PostBreakoutCloseRequest): Promise<any> => {
  return axios
    .post(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/breakout/close`, args, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
};
