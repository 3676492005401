import React from "react";
import { ReportPartnerIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { FlowsModal } from "../FlowsModal/FlowsModal";
import { modalToggle } from "../Modal/Modals";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";

export const ReportPartnerButton = () => {
  const { showModal } = useStore(
    useShallow((state) => ({
      showModal: state.showModals.includes("ReportPartner"),
    })),
  );

  return (
    <>
      <MenuButton
        buttonIcon={<ReportPartnerIcon fill={showModal ? "#FFA100" : "white"} />}
        onClick={() => {
          modalToggle("ReportPartner");
          useStore.setState({ showExternalContent: false, showChat: false });
        }}
        className={showModal ? "selected" : ""}
      >
        <FormattedMessage id="breakout_button" defaultMessage="Report Partner" />
      </MenuButton>
      <FlowsModal />
    </>
  );
};
