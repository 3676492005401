import { Core } from "../core";
import { useStore } from "../store";
import { AudioCommand } from "../../types/CableMessage";
import toast from "react-simple-toasts";

export const onAudioMessage = async function (this: Core, u: AudioCommand) {
  if (u.audio_command === "play") {
    toast(`${u.user_name} started the audio.`);
  }

  if (u.audio_command === "pause") {
    toast(`${u.user_name} paused the audio.`);
  }

  if (u.audio_command === "set_audio_time") {
    if (!u.skip_toast) toast(`${u.user_name} adjusted audio position.`);
    useStore.setState({
      audioProgress: u.time,
    });
    if (u.skip_toast) return;
  }

  // Don't apply your own commands again.
  // Return early here if the command is from yourself.
  if (u.user_id === this.currentUser.id) return;

  if (u.audio_command === "play" && !useStore.getState().narrating) {
    useStore.getState().skipBroadcastRef.current = true;
    useStore.setState({ narrating: true });
  }

  if (u.audio_command === "pause" && useStore.getState().narrating) {
    const state = useStore.getState();
    state.skipBroadcastRef.current = true;
    console.debug("[session-ui > onMessage]: Message requested pause audio");
    useStore.setState({
      narrating: false,
      audioProgress: state.audioProgress,
    });
  }

  if (u.audio_command === "set_audio_time") {
    const currentAudioObject = useStore.getState().currentAudioObject;
    if (!currentAudioObject) return;
    if (!u.time) return;

    currentAudioObject.currentTime = u.time;
  }
};
