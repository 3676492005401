import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { DailyEventObjectInputSettingsUpdated, DailyEventObjectNonFatalError } from "@daily-co/daily-js";
import { useInputSettings } from "@daily-co/daily-react";
import BlurIcon from "../../../assets/icons/BlurIcon";
import toast from "react-simple-toasts";
import { BLUR_LEVEL } from "../../../lib/defaults";
import MenuButton from "./MenuButton";
import { Blur } from "../../../assets/icons/Icons";
import DailyIframe from "@daily-co/daily-js";
import { modalClear } from "../Modal/Modals";

const BlurBackground = ({ version = "v1" }: { version?: string }) => {
  const intl = useIntl();
  const browserInfo = DailyIframe.supportedBrowser();
  const toggleBlurBackground = () => {
    updateInputSettings({
      video: {
        processor: {
          type: blurEnabled ? "none" : "background-blur",
          config: blurEnabled ? {} : { strength: BLUR_LEVEL },
        },
      },
    });
    modalClear("MoreMenu");
  };

  const { updateInputSettings, inputSettings } = useInputSettings({
    onError: useCallback((event: DailyEventObjectNonFatalError) => {
      toast(`Error setting up blur backgroud`, 2000);
      console.debug("BlurBackground " + event.errorMsg);
    }, []),

    onInputSettingsUpdated: useCallback((event: DailyEventObjectInputSettingsUpdated) => {
      if (event.inputSettings?.video?.processor?.type === "background-blur") {
        setBlurEnabled(true);
      } else {
        setBlurEnabled(false);
      }
    }, []),
  });
  const [blurEnabled, setBlurEnabled] = useState<boolean>(inputSettings?.video?.processor?.type === "background-blur");

  if (!browserInfo.supportsVideoProcessing) {
    return <></>;
  }
  if (version == "v2") {
    return (
      <MenuButton buttonIcon={<Blur fill="white" />} onClick={() => toggleBlurBackground()}>
        {!blurEnabled
          ? intl.formatMessage({
              description: "Menu button to toggle blur background",
              id: "blur_background.blur_background",
              defaultMessage: "Blur",
            })
          : intl.formatMessage({
              description: "Menu button to toggle unblur background",
              id: "blur_background.unblur_background",
              defaultMessage: "Unblur",
            })}
      </MenuButton>
    );
  }
  return (
    <>
      <button
        id="blur-background"
        className="mobile-btn"
        onClick={() => toggleBlurBackground()}
        title={
          !blurEnabled
            ? intl.formatMessage({
                description: "Menu button to toggle blur background",
                id: "blur_background.blur_background",
                defaultMessage: "Blur",
              })
            : intl.formatMessage({
                description: "Menu button to toggle unblur background",
                id: "blur_background.unblur_background",
                defaultMessage: "Unblur",
              })
        }
        style={{ flexWrap: "wrap" }}
      >
        <BlurIcon fill={blurEnabled ? "#ffa100" : "#ffffff"} height="22px" width="22px" />
      </button>
    </>
  );
};

export default BlurBackground;
