import React, { FC } from "react";
import styled from "styled-components";
import { BreakoutRoomMessage } from "../../../types/CableMessage";
import { ReturnIcon } from "../../../assets/icons/Icons";
import { modalShow } from "../Modal/Modals";
import { useStore } from "../../../core/store";

interface Props {
  rooms: BreakoutRoomMessage[];
  participants: Participant[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 9px;
  box-sizing: border-box;
  color: #000;
  width: 100%;
  height: calc(100% - 70px);
`;

export const StyledRoom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 12px 18px 20px;
  border-radius: 9px;
  background-color: #fff;
  color: #000;
  max-width: 100%;

  &.room-lobby {
    overflow-y: scroll;
  }
`;

export const ParticipantsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 0px;
  padding-top: 20px;
`;

export const ParticipantItem = styled.div`
  padding: 4px 7px;
  border-radius: 7px;
  background-color: #d9d9d9;
  width: fit-content;
  display: flex;
  align-items: center;

  &.offline {
    background-color: #ffbebe;
  }
`;

const ReturnParticipantItem = styled(ParticipantItem)`
  background: linear-gradient(90deg, #ff622e 27.21%, #ff8d24 122.48%);
  color: white;
  gap: 5px;
  cursor: pointer;
`;

export const OfflineSign = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d96363;
  margin-right: 5px;
`;

// const ProgressBarWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   gap: 4px;
//   padding: 10px 0px 5px 0px;
// `;

// const ProgressBar = styled.div`
//   width: 100%;
//   height: 10px;
//   background-color: #dedede;
//   border-radius: 12px;

//   &.active {
//     background-color: #ff5f25;
//   }
// `;

export function isInRoom(participant: Participant, room: BreakoutRoomMessage | undefined = undefined) {
  return (
    participant.location !== "offline" &&
    participant.location !== "dropped" &&
    (!room || participant.curRoomHashId === room.hashId)
  );
}

export const LobbyPage: FC<Props> = ({ rooms, participants }) => {
  const completedRooms = rooms.filter((r) => r.status == "completed");
  const closedRooms = rooms.filter((r) => r.status == "closed");
  const ongoingRooms = rooms.filter((r) => r.status != "closed" && r.status != "completed");

  // Sort the rooms by their hashId so that the screen doesn't flicker as the room states are updated
  completedRooms.sort((roomA, roomB) => roomB.hashId.localeCompare(roomA.hashId));
  closedRooms.sort((roomA, roomB) => roomB.hashId.localeCompare(roomA.hashId));
  ongoingRooms.sort((roomA, roomB) => roomB.hashId.localeCompare(roomA.hashId));

  const handleReturnRoom = (selectedParticipant: Participant) => {
    useStore.setState({
      selectedParticipant: selectedParticipant,
      selectedRoom: selectedParticipant.breakoutRoomHashId,
    });

    modalShow("ConfirmReturnRoom", false);
  };

  const returnToRoomParticipants = participants.filter((p) => p.breakoutRoomHashId !== undefined);
  const lobbyParticipants = participants.filter((p) => p.breakoutRoomHashId === undefined);

  return (
    <Wrapper>
      <StyledRoom className="room-lobby" data-testid={`room-lobby`}>
        <ParticipantsWrapper>
          {returnToRoomParticipants.map((participant) => {
            return (
              <ReturnParticipantItem
                className={isInRoom(participant) ? "" : "offline"}
                key={participant.id}
                onClick={() => handleReturnRoom(participant)}
              >
                {isInRoom(participant) ? null : <OfflineSign />}
                {participant.name}
                <ReturnIcon />
              </ReturnParticipantItem>
            );
          })}
          {lobbyParticipants.map((participant) => {
            return (
              <ParticipantItem className={isInRoom(participant) ? "" : "offline"} key={participant.id}>
                {isInRoom(participant) ? null : <OfflineSign />}
                {participant.name}
              </ParticipantItem>
            );
          })}
        </ParticipantsWrapper>
      </StyledRoom>
    </Wrapper>
  );
};
