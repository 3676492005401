import { useStore } from "../store";

type CommandType = "loadInvitation" | "setVisibility" | "nextStep" | "disconnectCall";

const ValidCommands: CommandType[] = ["loadInvitation", "setVisibility", "nextStep", "disconnectCall"];

interface CommandMessage {
  command: CommandType;
}

interface LoadInvitationMessage extends CommandMessage {
  command: "loadInvitation";
  hashedInvitationID: string;
}

interface SetVisibilityMessage extends CommandMessage {
  command: "setVisibility";
  visible: boolean;
}

interface NextStepMessage extends CommandMessage {
  command: "nextStep";
}
interface DisconnectCallMessage extends CommandMessage {
  command: "disconnectCall";
}

function isCommandMessage(data: unknown): data is CommandMessage {
  return !!data && ValidCommands.includes((data as CommandMessage).command);
}

function isLoadInvitationMessage(message: CommandMessage): message is LoadInvitationMessage {
  return !!message && message.command === "loadInvitation";
}

function isSetVisibilityMessage(message: CommandMessage): message is SetVisibilityMessage {
  return !!message && message.command === "setVisibility";
}

function isNextStepMessage(message: CommandMessage): message is NextStepMessage {
  return !!message && message.command === "nextStep";
}

function isDisconnectCallMessage(message: CommandMessage): message is DisconnectCallMessage {
  return !!message && message.command === "disconnectCall";
}

interface Props {
  forceNextStep: () => void;
  disconnectCall: () => void;
  setHashedInvitationID: (hashedID: string) => void;
}

export class PostMessageListener {
  forceNextStep: () => void;
  disconnectCall: () => void;
  setHashedInvitationID: (hashedID: string) => void;

  constructor(props: Props) {
    this.forceNextStep = props.forceNextStep;
    this.disconnectCall = props.disconnectCall;
    this.setHashedInvitationID = props.setHashedInvitationID;
    this.handleMessage = this.handleMessage.bind(this);

    window.addEventListener("message", this.handleMessage);
  }

  unmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  doCommand(command: CommandMessage) {
    console.debug("[session-ui]: Received postMessage command.", command);

    if (isLoadInvitationMessage(command)) {
      this.setHashedInvitationID(command.hashedInvitationID);
      return;
    }

    if (isSetVisibilityMessage(command)) {
      console.debug(`[session-ui]: Setting iframe visibility to ${command.visible}.`);
      useStore.setState({ iframeVisible: command.visible });
      return;
    }

    if (isNextStepMessage(command)) {
      console.debug(`[session-ui]: Going to next step.`);
      this.forceNextStep();
      return;
    }

    if (isDisconnectCallMessage(command)) {
      console.debug(`[session-ui]: Disconnecting call.`);
      this.disconnectCall();
      return;
    }
  }

  handleMessage(message: MessageEvent) {
    const command = message.data;
    if (isCommandMessage(command)) this.doCommand(command);

    if (command.command && !isCommandMessage(command)) {
      console.debug(`[session-ui]: Unknown command "${command.command}".`);
    }
  }
}
