import { useState, useEffect } from "react";

/**
 This hook will be used to find out seconds remianing between current date and passed expiration date.
 As soon as seconds remianing reaches to 0, this count down stops.
 */
const useCountDown = (expirationDate?: Date) => {
  const countDownTime = new Date(expirationDate || 0).getTime();
  const [countDown, setCountDown] = useState(Math.round((countDownTime - new Date().getTime()) / 1000));
  useEffect(() => {
    const interval = setInterval(() => {
      const seconds = Math.round((countDownTime - new Date().getTime()) / 1000);
      setCountDown(seconds);
      if (seconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownTime]);
  return countDown < 0 ? 0 : countDown;
};

export const useCountDownArray = (expirationDates: (Date | undefined)[]) => {
  const curTime = new Date().getTime();
  const countDownSeconds = expirationDates.map((d) => (d ? Math.round((d.getTime() - curTime) / 1000) : 0));
  const [countDowns, setCountDowns] = useState(countDownSeconds.map((s) => Math.max(0, s)));
  useEffect(() => {
    const interval = setInterval(() => {
      const curTime = new Date().getTime();
      const newCountDownSeconds = expirationDates.map((d) => (d ? Math.round((d.getTime() - curTime) / 1000) : 0));
      setCountDowns(newCountDownSeconds.map((s) => Math.max(0, s)));
      if (Math.max(...newCountDownSeconds) == 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownSeconds]);
  return countDowns;
};

export default useCountDown;
