import React from "react";
import { FormattedMessage } from "react-intl";
import { ConfigHeader, InfoText } from "../../Modal/ModalStyle";
import { CheckBox } from "grommet";
import { useStore } from "../../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { AutoJoinIcon } from "../../../../assets/icons/Icons";

export const AutoJoinConfig: React.FC = () => {
  const { autoJoin } = useStore(
    useShallow((state) => ({
      autoJoin: state.autoJoin,
    })),
  );

  const toggleAutoJoin = () => {
    useStore.setState({ autoJoin: !autoJoin });
  };

  return (
    <>
      <ConfigHeader>
        <AutoJoinIcon stroke="#000" />
        <FormattedMessage id="breakout.toggle_auto_join" defaultMessage="Auto Join" />
      </ConfigHeader>
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <InfoText style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <CheckBox checked={autoJoin} onChange={toggleAutoJoin} />
          <FormattedMessage
            id="breakout.toggle_auto_message"
            defaultMessage="Let late participants auto-join sessions"
          />
        </InfoText>
      </div>
    </>
  );
};
