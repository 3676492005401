import { DailyEventObjectActiveSpeakerChange } from "@daily-co/daily-js";
import { Core } from "../core";
import { useStore } from "../store";
import { PARTICIPANTS_PER_PAGE } from "../../lib/defaults";

export const onActiveSpeakerChange = function (this: Core, event?: DailyEventObjectActiveSpeakerChange) {
  console.debug("[session-ui]: onActiveSpeakerChange:", event?.activeSpeaker?.peerId);

  if (event?.activeSpeaker?.peerId) {
    const participants = Object.values(useStore.getState().participants) as Participant[];
    const isMobile = useStore.getState().deviceClassification.size === "small";

    const activeSpeakerDailySessionID = event?.activeSpeaker?.peerId;
    const activeSpeakerWarmspaceID = participants.find((p) => p.dailySessionID === activeSpeakerDailySessionID)?.id;

    if (!activeSpeakerWarmspaceID) {
      console.error("[session-ui]: Active speaker not found in participants list");
      return;
    }

    const newParticipantsLastActiveDates = Object.assign({}, useStore.getState().participantsLastActiveDates);

    // Initialize the newParticipantsLastActiveDates so that every participant has a date
    const now = new Date();
    const MS_PER_MINUTE = 60000;
    for (let i = 0; i < participants.length; i++) {
      const participant = participants[i];
      if (!newParticipantsLastActiveDates[participant.id]) {
        newParticipantsLastActiveDates[participant.id] = new Date(now.getTime() - (MS_PER_MINUTE + i));
      }
    }

    // Mobile needs to always move the active speaker to the top of the list
    // But Desktop should only move people to the top of the list if they are not
    // on page 1. That way, the active speaker doesn't move around too much on Desktop.
    if (isMobile) {
      newParticipantsLastActiveDates[activeSpeakerWarmspaceID] = now;
    } else {
      const sortedParticipants = participants.sort((a, b) => {
        const aLastActiveDate = newParticipantsLastActiveDates[a.id] || 0;
        const bLastActiveDate = newParticipantsLastActiveDates[b.id] || 0;

        if (aLastActiveDate > bLastActiveDate) return -1;
        if (bLastActiveDate < aLastActiveDate) return 1;
        return 0;
      });

      const activeSpeakerIndex = sortedParticipants.findIndex((p) => p.id === activeSpeakerWarmspaceID);

      if (activeSpeakerIndex > PARTICIPANTS_PER_PAGE - 1) {
        newParticipantsLastActiveDates[activeSpeakerWarmspaceID] = now;

        // While we're moving video boxes around, move anyone who doesn't have their camera on
        // and isn't the active speaker off the first page to prioritize those with their camera on
        const offCameraFirstPageParticipants: Participant[] = [];
        const onCameraLaterPageParticipants: Participant[] = [];
        const dailyParticipants = Core.GetInstance().callObject?.participants();
        sortedParticipants.forEach((p, idx) => {
          const dailyParticipant = dailyParticipants?.[p.dailySessionID];
          const cameraOn = dailyParticipant && dailyParticipant?.tracks?.video?.state !== "playable";
          if (idx > 0 && idx < PARTICIPANTS_PER_PAGE && cameraOn) {
            offCameraFirstPageParticipants.push(p);
          }
          if (
            idx >= PARTICIPANTS_PER_PAGE &&
            cameraOn &&
            onCameraLaterPageParticipants.length < offCameraFirstPageParticipants.length
          ) {
            onCameraLaterPageParticipants.push(p);
          }
        });
        // Swap the last active dates between those with cameras on and off to move the on-participants to the first page
        for (let i = 0; i < onCameraLaterPageParticipants.length; i++) {
          const onCameraParticipant = onCameraLaterPageParticipants[i];
          const offCameraParticipant = offCameraFirstPageParticipants[i];

          const onCameraActiveDate = newParticipantsLastActiveDates[onCameraParticipant.id];
          const offCameraActiveDate = newParticipantsLastActiveDates[offCameraParticipant.id];

          newParticipantsLastActiveDates[onCameraParticipant.id] = offCameraActiveDate;
          newParticipantsLastActiveDates[offCameraParticipant.id] = onCameraActiveDate;
        }
      }
    }

    // IN PROGRESS: This is the new system for showing the active speaker at the top of the list.
    // Alternate system:
    // const newParticipantSorting = Array.from(useStore.getState().participantSorting);

    // // Remove duplicate
    // if (newParticipantSorting.includes(activeSpeakerWarmspaceID)) {
    //   newParticipantSorting.splice(newParticipantSorting.indexOf(activeSpeakerWarmspaceID), 1);
    // }

    // // Add the active speaker to the top of the list
    // newParticipantSorting.unshift(activeSpeakerWarmspaceID);

    useStore.setState({
      participantsLastActiveDates: newParticipantsLastActiveDates,
      // participantSorting: newParticipantSorting,
    });
  }
};
