import { DEFAULT_LOCALE } from "./defaults";

interface TimeUnits {
  second: { [key: string]: string[] };
  minute: { [key: string]: string[] };
  hour: { [key: string]: string[] };
  day: { [key: string]: string[] };
  year: { [key: string]: string[] };
}

const time_unit = {
  second: {
    en: ["sec", "sec"],
    es: ["segundo", "segundos"],
    fr: ["seconde", "secondes"],
    de: ["Sekunde", "Sekunden"],
    it: ["secondo", "secondi"],
    sv: ["sekund", "sekunder"],
    pt: ["segundo", "segundos"],
  },
  minute: {
    en: ["min", "min"],
    es: ["minuto", "minutos"],
    fr: ["minute", "minutes"],
    de: ["Minute", "Minuten"],
    it: ["minuto", "minuti"],
    sv: ["minut", "minuter"],
    pt: ["minuto", "minutos"],
  },
  hour: {
    en: ["h", "h"],
    es: ["hora", "horas"],
    fr: ["heure", "heures"],
    de: ["Stunde", "Stunden"],
    it: ["ora", "ore"],
    sv: ["timme", "timmar"],
    pt: ["hora", "horas"],
  },
  day: {
    en: ["d", "d"],
    es: ["día", "días"],
    fr: ["jour", "jours"],
    de: ["Tag", "Tage"],
    it: ["giorno", "giorni"],
    sv: ["dag", "dagar"],
    pt: ["dia", "dias"],
  },
  year: {
    en: ["yr", "yr"],
    es: ["año", "años"],
    fr: ["an", "ans"],
    de: ["Jahr", "Jahre"],
    it: ["anno", "anni"],
    sv: ["år", "år"],
    pt: ["ano", "anos"],
  },
} as TimeUnits;

const less_than_a_second = {
  en: "less than a second",
  es: "menos de un segundo",
  fr: "moins d'une seconde",
  de: "weniger als eine Sekunde",
  it: "meno di un secondo",
  sv: "mindre än en sekund",
  pt: "menos de um segundo",
} as LocaleText;

export function millisecondsToStr(milliseconds: number, locale: string) {
  // TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  function get_unity(number: number, unity: string[]) {
    return number > 1 ? unity[1] : unity[0];
  }

  let temp = Math.floor(milliseconds / 1000);
  const years = Math.floor(temp / 31536000);
  if (years) {
    return years + " " + get_unity(years, time_unit.year[locale] || time_unit.year[DEFAULT_LOCALE]);
  }

  const days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + " " + get_unity(days, time_unit.day[locale] || time_unit.day[DEFAULT_LOCALE]);
  }
  const hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + " " + get_unity(hours, time_unit.hour[locale] || time_unit.hour[DEFAULT_LOCALE]);
  }
  const minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + " " + get_unity(minutes, time_unit.minute[locale] || time_unit.minute[DEFAULT_LOCALE]);
  }
  const seconds = temp % 60;
  if (seconds) {
    return seconds + " " + get_unity(seconds, time_unit.second[locale] || time_unit.second[DEFAULT_LOCALE]);
  }
  return less_than_a_second[locale] || less_than_a_second[DEFAULT_LOCALE];
}

/*
This function will return seconds in mm:ss format
i.e 640 seconds as 10:40
but if totalSeconds is >= 3600 then it will return ""
*/
export function secondsToMMSS(totalSeconds: number) {
  function padDigits(val: number, noOfdigits: number) {
    return Array(Math.max(noOfdigits - String(val).length + 1, 0)).join("0") + val;
  }

  if (totalSeconds >= 3600) {
    return "";
  }

  const divisor_for_minutes = totalSeconds % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return padDigits(minutes, 2) + ":" + padDigits(seconds, 2);
}
