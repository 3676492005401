import axios from "axios";

export default async (hashedInvitationID: string, signal: AbortSignal): Promise<InvitationResponse> => {
  return axios
    .get(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/session_app/invitations/${hashedInvitationID}`, {
      signal,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      response.data.startTime = new Date(response.data.startTime);
      response.data.hashedID = hashedInvitationID;
      return response.data;
    })
    .catch();
};
