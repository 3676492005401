import getInvitation from "../../lib/requests/getInvitation";
import getUser from "../../lib/requests/getUser";

export const initialRequests = async (hashedInvitationID: string) => {
  const controller = new AbortController();

  try {
    const [invitation, currentUser] = await Promise.all([
      getInvitation(hashedInvitationID, controller.signal),
      getUser(controller.signal),
    ]);

    invitation.localDate = new Date();

    return { invitation, currentUser };
  } catch (e) {
    controller.abort();
    throw e;
  }
};
