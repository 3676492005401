// hideLoading removes the static loading message from index.html
// For people with slow connections, the loading message would show up
// while this javascript bundle is downloading.
export const hideLoading = () => {
  document.body.classList.remove("loading");
};

export const showLoading = () => {
  document.body.classList.add("loading");
};
