{
  "defaultV2": {
    "button": {
      "colors": {
        "primary": "#FF5F25",
        "secondary": "#999999",
        "primaryHover": "#FF9900",
        "primaryClicked": "#FF9900",
        "warning": "#561709",
        "choices": "#3F3F3F"
      }
    },
    "body": {
      "background": "#222222",
      "foreground": "#FFFFFF"
    }
  },

  "default": {
    "button": {
      "color": "#fff",

      "border": {
        "radius": "5px"
      },
      "colors": {
        "primary": "#FFA100",
        "secondary": "#222",
        "primaryHover": "#FF9900",
        "primaryClicked": "#FF9900",
        "warning": "#561709",
        "choices": "#3F3F3F"
      }
    },
    "body": {
      "background": "#222222",
      "foreground": "#FFFFFF"
    }
  },
  "light": {
    "button": {
      "colors": {
        "primary": "#FFA100",
        "secondary": "#999999",
        "primaryHover": "#FF9900",
        "primaryClicked": "#FF9900",
        "background": "#222222",
        "foreground": "#FFFFFF",
        "warning": "blue",
        "choices": "#3F3F3F"
      }
    },
    "body": {
      "background": "#222222",
      "foreground": "#FFFFFF"
    }
  }
}
