export interface InterfaceRulesResponse {
  allowChat: boolean;
  allowModeSwitching: boolean;
}

export const InterfaceRules = (
  invitation?: { solo?: boolean; anytimeSession?: boolean },

  flow?: { disableChat?: boolean; disableModeSwitching?: boolean },
): InterfaceRulesResponse => {
  let allowChat = true;
  let allowModeSwitching = true;

  if (invitation && invitation.solo) allowModeSwitching = false;
  if (invitation && !invitation.anytimeSession) allowModeSwitching = false;
  if (flow && flow.disableModeSwitching) allowModeSwitching = false;

  if (flow?.disableChat) allowChat = false;
  if (invitation && invitation.solo) allowChat = false;
  if (flow && flow.disableChat) allowChat = false;

  return {
    allowChat,
    allowModeSwitching,
  };
};
