import axios from "axios";

export default async (hashedInvitationID: string, clientID: string): Promise<TokenResponse> => {
  //Added to make cosmos working
  if (clientID === "cosmos-clientID") return { token: "test" } as TokenResponse;
  return axios
    .get(
      `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/session_app/invitations/${hashedInvitationID}/tokens/${clientID}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    )
    .then((response) => {
      return response.data;
    });
};
