import axios from "axios";

interface PostBreakoutRequest {
  lobbyInvitationHashId: string;
  breakoutInvitationHashId: string;
  excludeUserIds: number[];
  minParticipants: number;
  maxParticipants: number;
  preferredParticipants: number;
  meetingLocation: "online" | "in_person";
  autoJoin: boolean;
  duration: number | null;
  prompts: string[] | null;
  durationPreference: DurationPreference;
}

export default async (args: PostBreakoutRequest): Promise<any> => {
  return axios
    .post(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/breakout`, args, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
};
