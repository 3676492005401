import styled from "styled-components";

export const PaginationControls = styled.div`
  margin-top: 10px;
  height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  .pageIndicator {
    height: 7px;
    width: 7px;
    border-radius: 1px;
    background-color: #777;
    margin: 0px 2px;
    cursor: pointer;

    &.active {
      background-color: #fff;
    }
  }
`;
