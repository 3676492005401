import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Close, Spinner } from "../../../assets/icons/Icons";
import { Overlay, Card, CardBody, CardHeader, CardFooter } from "../Modal/ModalStyle";
import { useStore } from "../../../core/store";
import { modalClear } from "../Modal/Modals";
import { useShallow } from "zustand/react/shallow";
interface Props {
  visible: boolean;
  setShow: (visible: false) => void;
  inPerson: boolean;
}

export const SwitchDigitalPhysicalModal: FC<Props> = ({ visible, setShow, inPerson }) => {
  const intl = useIntl();

  const { core } = useStore(
    useShallow((state) => ({
      core: state.core,
    })),
  );

  const [loading, setLoading] = React.useState(false);
  if (!visible) return null;
  return (
    visible && (
      <>
        <Card>
          <CardHeader>
            <FormattedMessage
              description="Header text for Switch Digital Physical Modal"
              id="switch_physical_digital_modal.heading"
              defaultMessage={"Switching mode"}
            />
            <Button
              className="link"
              onClick={() => {
                setShow(false);
                modalClear("MoreMenu");
              }}
            >
              <Close />
            </Button>
          </CardHeader>
          <CardBody>
            {inPerson ? (
              <FormattedMessage
                description="Text description to switch to video mode"
                id="switch_physical_digital_modal.digital_switch_description"
                defaultMessage={
                  "The video mode enables the camera and microphone and is optimized for doing a Warmspace over a video chat."
                }
              />
            ) : (
              <FormattedMessage
                description="Text description to switch to physical mode"
                id="switch_physical_digital_modal.physical_switch_description"
                defaultMessage={"This disables the camera and microphone and is optimized for an in-person connection."}
              />
            )}
          </CardBody>
          <CardFooter>
            <Button onClick={() => setShow(false)} className="link" style={{ color: "black" }}>
              <FormattedMessage
                description="Button text to cancel switch"
                id="switch_digital_physical_modal.cancel"
                defaultMessage={"Cancel"}
              />
            </Button>

            {loading ? (
              <Spinner />
            ) : (
              <Button
                style={{ width: "auto", fontSize: "16px", padding: "8px 15px" }}
                onClick={async () => {
                  core.subscription?.perform("switch_in_person_mode", {
                    in_person: !useStore.getState().inPerson,
                  });
                  if (inPerson) {
                    setLoading(true);
                    setShow(false);
                  } else {
                    setShow(false);
                  }
                  modalClear("MoreMenu");
                }}
              >
                {inPerson
                  ? intl.formatMessage({
                      description: "Button text indicating switch mode to digital",
                      id: "switch_physical_digital_modal.join_digital",
                      defaultMessage: "Switch mode",
                    })
                  : intl.formatMessage({
                      description: "Button text indicating switch mode to physical",
                      id: "switch_physical_digital_modal.join_physical",
                      defaultMessage: "Switch mode",
                    })}
              </Button>
            )}
          </CardFooter>
        </Card>
        <Overlay
          onClick={() => {
            setShow(false);
          }}
        />
      </>
    )
  );
};

export default SwitchDigitalPhysicalModal;
