import React, { FC, useEffect } from "react";
import { useStore } from "../../core/store";
import { useShallow } from "zustand/react/shallow";
import { AudioProgress } from "../UI/AudioProgress";
import styled from "styled-components";
import {
  isChoicesStep,
  isContentStep,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
  isIframeStep,
} from "../../types/type_guards";
import ChoiceStep from "../UI/Steps/ChoiceStep";
import { IFrameStep } from "../UI/Steps/IFrameStep";
import Actions from "../UI/Actions/Actions";
import inPersonActionCheck from "../../assets/icons/in-person-action-check.svg";
import FeedbackScoreStep from "../UI/Steps/FeedbackScoreStep";
import FeedbackWordsStep from "../UI/Steps/FeedbackWordsStep";
import { ExternalContentTile } from "../UI/VideoGrid/ExternalContentTile";
import { modalShow } from "../UI/Modal/Modals";
import logo from "../../assets/warmspace_logo_color.svg";
import { InPersonStepCountdown } from "../UI/InPersonStepCountdown/InPersonStepCountdown";
import { FacilitatorControl } from "../UI/FacilitatorControl/FacilitatorControl";
import { AddPartner } from "../UI/AddPartner/AddPartner";

interface InPersonSessionProps {}

const InPersonWrapper = styled.div`
  display: flex;
  color: black;
  height: calc(100% - 60px);
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffeecd;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  text-align: center;
  border-radius: 10px;

  .top {
    height: 25px;
    display: flex;
  }

  .middle {
    flex: 1;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.35;
      color: #2a2a2a;
      max-width: 900px;
      margin: 0 auto;
      white-space: pre-line;
      font-family: "ITC Clearface", sans-serif;
      transition: all 0.4s ease;
      opacity: 1;
      position: relative;
      top: 0px;

      @media (max-width: 400px) {
        font-size: 1.6rem;
      }

      &.changing {
        top: -20px;
        opacity: 0;
      }
    }
  }

  .bottom {
    display: flex;
    height: 60px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .participants {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      cursor: pointer;

      &:hover {
        background-color: rgba(50, 50, 50, 0.1);
      }

      &.with-names {
        padding-top: 10px;
      }

      background-color: rgba(50, 50, 50, 0.05);
      border: 1px solid rgba(50, 50, 50, 0.1);
      border-radius: 10px;

      font-size: 16px;

      .total-checkmark {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        position: relative;
        top: 0px;
      }

      .participant {
        border-radius: 40px;
        font-size: 14px;
        color: #555;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        position: relative;
        font-weight: bold;

        &:first-child {
          padding-left: 5px;
        }

        .doneIndicator {
          padding-left: 4px;
          position: absolute;
          top: -5px;
          right: -5px;
        }

        &.offline {
          opacity: 0.4;
        }

        &.ready-room {
          opacity: 0.4;
        }

        &.PENDING {
          color: rgb(255, 119, 41);
          font-weight: bold;
        }

        &.COMPLETED {
          color: rgb(255, 119, 41, 0.7);
          font-weight: bold;

          .doneIndicator {
            display: block;
            opacity: 1;

            svg {
              width: 15px;
              height: 15px;
              display: block;
            }

            * {
              padding: 0;
              margin: 0;
            }
            .wrapper {
              height: 100vh;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #eee;
            }
            .checkmark__circle {
              stroke-dasharray: 166;
              stroke-dashoffset: 166;
              stroke-width: 2;
              stroke-miterlimit: 10;
              stroke: #ff622e;
              fill: none;
              animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            }
            .checkmark {
              border-radius: 50%;
              display: block;
              stroke-width: 7;
              stroke: #fff;
              stroke-miterlimit: 10;
              margin: 10% auto;
              box-shadow: inset 0px 0px 0px #ff622e;
              animation:
                fill 0.4s ease-in-out 0s forwards,
                scale 0.3s ease-in-out 0s both;
            }
            .checkmark__check {
              transform-origin: 50% 50%;
              stroke-dasharray: 48;
              stroke-dashoffset: 48;
              animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.3s forwards;
            }

            @keyframes stroke {
              100% {
                stroke-dashoffset: 0;
              }
            }

            @keyframes scale {
              0%,
              100% {
                transform: none;
              }
              50% {
                transform: scale3d(1.1, 1.1, 1);
              }
            }

            @keyframes fill {
              100% {
                box-shadow: inset 0px 0px 0px 30px #ff622e;
              }
            }
          }
        }
      }
    }
  }
`;

export function truncate(str: string, length: number) {
  return str.length > length ? str.substring(0, length - 1) + "..." : str;
}

export const InPersonSession: FC<InPersonSessionProps> = () => {
  console.debug("[session-ui]: InPersonSession render");
  const [currentText, setCurrentText] = React.useState<string | undefined>("");
  const [currentTextChange, setCurrentTextChange] = React.useState<boolean>(false);

  const {
    participants,
    stepContent,
    stepData,
    roleData,
    currentStep,
    iframeVisible,
    locale,
    showExternalContent,
    invitation,
    currentUser,
  } = useStore(
    useShallow((state) => ({
      participants: state.participants,
      stepContent: state.stepContent,
      roleData: state.roleData,
      stepData: state.stepData,
      currentStep: state.currentStep,
      iframeVisible: state.iframeVisible,
      locale: state.locale,
      showExternalContent: state.showExternalContent,
      invitation: state.invitation,
      currentUser: state.currentUser,
    })),
  );

  // This is about animating the text when new text comes in.
  useEffect(() => {
    if (isContentStep(currentStep) && currentStep.audioFile[locale]?.url) {
      setCurrentText(stepContent?.text);
      return;
    }
    setCurrentTextChange(true);

    const timeout = setTimeout(() => {
      setCurrentTextChange(false);
      setCurrentText(stepContent?.text);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [stepContent?.text]);

  const speakerBackground = invitation?.speakerBackground || "";
  const listenerBackground = invitation?.listenerBackground || "";

  const background = roleData?.[currentUser.id]?.role === "A" ? speakerBackground : listenerBackground;

  const participantsArray: Participant[] = Object.values(participants);
  return (
    <InPersonWrapper style={{ backgroundImage: `url(${background})` }}>
      <div className="top">
        <AddPartner />
        <InPersonStepCountdown />
        <FacilitatorControl />
      </div>
      <div className="middle">
        <AudioProgress role={roleData?.[currentUser.id]?.role} step={currentStep} userID={currentUser.id} />
        {invitation.lobby && !showExternalContent && (
          <>
            <img src={logo} width="225px" style={{ marginBottom: "16px" }} />
          </>
        )}
        {(isContentStep(currentStep) || (isIframeStep(currentStep) && !iframeVisible)) && (
          <p className={currentTextChange ? "changing" : ""}>{currentText}</p>
        )}
        <ExternalContentTile />
      </div>
      <div className="bottom">
        {isContentStep(currentStep) && <Actions wrapperClasses={"nohide"} disableAutoHide={() => {}} />}

        <div
          className={(participantsArray.length <= 8 ? "with-names " : "") + "participants"}
          onClick={() => {
            modalShow("ManageParticipants");
          }}
        >
          {participantsArray.length <= 8 &&
            participantsArray
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .slice(0, 8)
              .map((participant) => (
                <div
                  key={participant.id}
                  className={"participant " + roleData[participant.id]?.actionStatus + " " + participant.location}
                >
                  {truncate(participant.name, 10)}

                  <div className={"doneIndicator"}>
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                      <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                      <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                  </div>
                </div>
              ))}

          {participantsArray.length > 8 && (
            <>
              <img src={inPersonActionCheck} className="total-checkmark" />
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              {Object.values(roleData).filter((x) => x.actionStatus == "COMPLETED").length}/{participantsArray.length}
            </>
          )}
        </div>
      </div>

      <IFrameStep />
      {isChoicesStep(currentStep) && (
        <ChoiceStep
          stepData={stepData}
          roleData={roleData}
          yourID={currentUser.id}
          step={currentStep}
          choices={stepContent?.choices}
          text={stepContent?.text}
        />
      )}
      {isFeedbackScoreStep(currentStep) && (
        <FeedbackScoreStep
          stepData={stepData}
          roleData={roleData}
          yourID={currentUser.id}
          step={currentStep}
          stepContent={stepContent!}
          text={stepContent?.text}
        />
      )}

      {isFeedbackWordsStep(currentStep) && (
        <FeedbackWordsStep
          stepData={stepData}
          yourID={currentUser.id}
          step={currentStep}
          stepContent={stepContent!}
          text={stepContent?.text}
        />
      )}
    </InPersonWrapper>
  );
};
