/* eslint-disable formatjs/no-literal-string-in-jsx */
import styled from "styled-components";
import React, { useEffect } from "react";
import ParticipantsButton from "./ParticipantsButton";
import SwitchDigitalPhysicalButton from "./SwitchDigitalPhysicalButton";
import BlurBackground from "./BlurBackground";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { useFullscreenHandler } from "../../../hooks/useFullscreenhandler";
import MenuButton from "./MenuButton";
import expand from "../../../assets/icons/expand.svg";
import compress from "../../../assets/icons/compress.svg";
import { FormattedMessage } from "react-intl";
import { Video, Microphone, Speaker } from "../../../assets/icons/Icons";
import { Menu } from "grommet";
import { useDevices } from "@daily-co/daily-react";
import { modalClear } from "../Modal/Modals";
import RaiseHandButton from "./RaiseHandButton";
import RecordButton from "./RecordButton";

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  background-color: #222;
  padding: 10px 10px;

  border-radius: 10px;
  box-sizing: border-box;

  position: relative;
  z-index: 10;
  color: #fff;
`;

// const EmojisWrapper = styled.div`
//   display: flex;
//   aling-items: center;
//   justify-content: space-evenly;
//   padding: 8px;
// `;

const Separater = styled.div`
  width: 90%;
  height: 0px;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 10px;

  border-top: 1px solid #444;
  border-bottom: 1px solid #111;
`;

const SubMenuButtons = styled.div`
  gap: 10px;
  display: flex;
  justify-content: space-around;
  width: 100%;

  box-sizing: border-box;
`;

const DeviceMenu = styled.div`
  display: flex;

  width: 90%;
  margin: auto;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 8px;

  button {
    border: 1px solid #383838;
    background-color: #333;
    border-radius: 10px;
    width: 100%;

    > div {
      padding: 7px 6px;
    }

    &:hover {
      background-color: #555;
    }
  }
`;

const Label = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  gap: 10px;

  word-break: break-word;

  svg {
    width: 20px;
    flex-shrink: 0;
  }

  &.microphone {
    svg {
      height: 14px;
    }
  }

  svg {
    width: 15px;
  }
`;

const VolumeSliders = styled.div`
  display: flex;
  font-size: 14px;
  gap: 10px;
  width: 90%;
  margin: auto;
  margin-bottom: 10px;
`;

const VolumeSliderContainer = styled.div`
  text-align: left;
  padding: 4px 17px;
  padding-top: 8px;
  background-color: #333;
  border-radius: 10px;
  width: 100%;

  input {
    width: 100%;
    margin: 0px;
  }
`;

function truncate(str: string, length: number) {
  return str.length > length ? str.substring(0, length - 1) + "…" : str;
}

const MoreMenu = () => {
  const { inPerson, musicVolume, musicPlaying, narrationVolume, interfaceRules, deviceClassification, invitation } =
    useStore(
      useShallow((state) => ({
        inPerson: state.inPerson,
        musicVolume: state.musicVolume,
        musicPlaying: state.musicPlaying,
        narrationVolume: state.narrationVolume,
        interfaceRules: state.interfaceRules,
        deviceClassification: state.deviceClassification,
        invitation: state.invitation,
      })),
    );

  const {
    currentCam,
    currentMic,
    currentSpeaker,
    refreshDevices,
    cameras,
    microphones,
    speakers,
    setCamera,
    setMicrophone,
    setSpeaker,
  } = useDevices();

  useEffect(() => {
    if (!inPerson) {
      refreshDevices();
    }
  }, [inPerson]);

  function changeMusicVolume(volume: string) {
    if (volume === "0" && musicPlaying) {
      useStore.setState({ musicPlaying: false });
    } else if (volume !== "0" && !musicPlaying) {
      useStore.setState({ musicPlaying: true });
    }
    useStore.setState({ musicVolume: parseFloat(volume) });
  }

  function changeNarrationVolume(volume: string) {
    useStore.setState({ narrationVolume: parseFloat(volume) });
  }

  const { toggleFullscreen, isFullscreen } = useFullscreenHandler();

  const isBiggerScreen = deviceClassification.size !== "small";
  const isLobby = invitation.lobby;

  return (
    <Wrapper>
      {/* <EmojisWrapper>
        <Button className="emoji" title="Thumbs up">
          &#128077;
        </Button>
        <Button className="emoji" title="Red heart">
          &#x2764;&#xFE0F;
        </Button>
        <Button className="emoji" title="Tears of joy">
          &#128514;
        </Button>
        <Button className="emoji" title="Open Mouth">
          &#128558;
        </Button>
        <Button className="emoji" title="Disappointed but relieved face">
          &#128549;
        </Button>
        <Button className="emoji" title="Pray">
          &#128591;
        </Button>
      </EmojisWrapper>
       */}
      {(!invitation.disableMusic || invitation.audioPaths.length > 0) && (
        <VolumeSliders>
          {!invitation.disableMusic && !inPerson && (
            <VolumeSliderContainer>
              <label>Music volume:</label>
              <input
                onChange={(e) => changeMusicVolume(e.target.value)}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={invitation.disableMusic ? 0 : musicVolume}
                disabled={invitation.disableMusic}
                data-testid="music-volume"
              />
            </VolumeSliderContainer>
          )}

          {invitation.audioPaths.length > 0 && (
            <VolumeSliderContainer>
              <label>Narration volume:</label>
              <input
                onChange={(e) => changeNarrationVolume(e.target.value)}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={narrationVolume}
                data-testid="narration-volume"
              />
            </VolumeSliderContainer>
          )}
        </VolumeSliders>
      )}

      {!inPerson && (
        <>
          <DeviceMenu>
            <Menu
              size="small"
              icon={false}
              dropBackground={"#444"}
              label={
                <Label>
                  <Video fill="white" width={21} />
                  {truncate(currentCam?.device.label || "Camera", 16)}
                </Label>
              }
              items={cameras.map((c) => ({
                label: c.device.label,
                onClick: () => {
                  localStorage.setItem("warmspace.cameraDeviceId", c.device.deviceId);
                  setCamera(c.device.deviceId);
                },
              }))}
            />
            <Menu
              size="small"
              icon={false}
              dropBackground={"#444"}
              label={
                <Label className="microphone">
                  <Microphone fill="white" />
                  {truncate(currentMic?.device.label || "Microphone", 16)}
                </Label>
              }
              items={microphones.map((m) => ({
                label: m.device.label,
                onClick: () => {
                  localStorage.setItem("warmspace.microphoneDeviceId", m.device.deviceId);
                  setMicrophone(m.device.deviceId);
                },
              }))}
            />
            <Menu
              size="small"
              icon={false}
              dropBackground={"#444"}
              label={
                <Label>
                  <Speaker />
                  {truncate(currentSpeaker?.device.label || "Speaker", 16)}
                </Label>
              }
              items={speakers.map((s) => ({
                label: s.device.label,
                onClick: () => {
                  setSpeaker(s.device.deviceId);
                },
              }))}
            />
          </DeviceMenu>
          <Separater />
        </>
      )}

      <SubMenuButtons>
        {!isBiggerScreen && <ParticipantsButton />}
        {!inPerson && !isBiggerScreen && <RecordButton />}
        {!inPerson && !isBiggerScreen && isLobby && <RaiseHandButton />}

        {!inPerson && <BlurBackground version="v2" />}
        {interfaceRules.allowModeSwitching && <SwitchDigitalPhysicalButton inPerson={inPerson} />}

        <MenuButton
          buttonIcon={<img height="18px" src={isFullscreen ? compress : expand} />}
          onClick={() => {
            modalClear("MoreMenu");
            toggleFullscreen(isFullscreen);
          }}
        >
          {isFullscreen && (
            <FormattedMessage
              description="More Menu button text to exit fullscreen "
              id="more_menu.exit-fullscreen"
              defaultMessage={"Exit Fullscreen"}
            />
          )}
          {!isFullscreen && (
            <FormattedMessage
              description="More Menu button text to enter fullscreen"
              id="more_menu.enter-fullscreen"
              defaultMessage={"Fullscreen"}
            />
          )}
        </MenuButton>
      </SubMenuButtons>
    </Wrapper>
  );
};

export default MoreMenu;
