import React, { FC } from "react";
import { VideoGridTile } from "./VideoGridTile";

interface VideoGridDesktopOneOnOneProps {
  filteredParticipants: Participant[];
}

export const VideoGridDesktopOneOnOne: FC<VideoGridDesktopOneOnOneProps> = ({ filteredParticipants }) => {
  return (
    <VideoGridTile
      dailySessionID={filteredParticipants[0].dailySessionID}
      userName={filteredParticipants[0].name}
      warmspaceID={filteredParticipants[0].id}
      participantLocation={filteredParticipants[0].location}
      key={`video-${filteredParticipants[0].id}`}
    />
  );
};
