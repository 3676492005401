import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocalParticipant, useVideoTrack } from "@daily-co/daily-react";
import TileNoVideo from "./TileNoVideo";

interface VideoGridTileProps {
  warmspaceID: number;
  participantLocation: ParticipantLocation;
  participantCount: number;
  userName: string;
}

export const VideoGridTileContainer = styled.div`
  box-sizing: border-box;
  height: 120px;
  width: 150px;
  position: absolute;
  bottom: 38px;
  right: 0px;
  overflow: hidden;
  border-radius: 10px;
  transition: width 0.3s ease;
  background-color: #000;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  video {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;

    transform: rotatey(180deg);
  }
`;

export const SelfTile: FC<VideoGridTileProps> = ({ participantLocation, userName }) => {
  const localParticipant = useLocalParticipant();
  const videoElement = useRef<HTMLVideoElement>(null);

  const videoTrack = useVideoTrack(localParticipant?.session_id || "");

  useEffect(() => {
    const video = videoElement.current;
    if (!video || !videoTrack?.persistentTrack) return;
    /*  The track is ready to be played. We can show video of the participant in the UI.*/
    video.srcObject = new MediaStream([videoTrack?.persistentTrack]);
  }, [videoTrack?.persistentTrack]);

  const hasVideo = !videoTrack.isOff;

  return (
    <VideoGridTileContainer>
      <div style={{ width: "100%", height: "100%" }}>
        <div className="scrollGrabber"></div>
        {hasVideo && <video autoPlay muted playsInline disablePictureInPicture ref={videoElement} />}
        {!hasVideo && (
          <TileNoVideo
            participantLocation={participantLocation}
            name={userName}
            isLocal={false}
            dropParticipant={() => {}}
          />
        )}
      </div>
    </VideoGridTileContainer>
  );
};
