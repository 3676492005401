const WarmspaceTheme = {
  global: {
    drop: {
      border: {
        radius: "10px",
      },
    },

    colors: {
      brand: "rgb(255, 161, 0)",
    },
  },

  tip: {
    content: {
      background: "#444",
      color: "#fff",
      elevation: "medium",
      size: "12px",
      margin: "xsmall",
      pad: { vertical: "xsmall", horizontal: "medium" },
      round: "small",
    },
  },

  button: {
    color: "#fff",

    size: {
      small: {
        border: {
          radius: "5px",
        },
      },
    },
  },
};

export default WarmspaceTheme;
