import React, { FC } from "react";
import styled from "styled-components";
import { FlowsList } from "./FlowsList";

interface Props {
  flows: LobbyInvitation[];
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 70px);
  box-sizing: border-box;
  color: #000000;
`;

export const obtainFilteredParticipants = (lobbyParticipants: Participant[], excludedParticipantIds: number[]) => {
  const inSessionParticipants: Participant[] = lobbyParticipants.filter((p) => p.location === "session");
  const inSessionParticipantIds = inSessionParticipants.map((p) => p.id);

  // Explicitly exclude the ones who may still be in the ready room
  const allExcludedParticipantIds = excludedParticipantIds.filter((pid) => inSessionParticipantIds.indexOf(pid) != -1);
  lobbyParticipants.forEach((p) =>
    p.location !== "session" && allExcludedParticipantIds.indexOf(p.id) == -1
      ? allExcludedParticipantIds.push(p.id)
      : false,
  );

  const allParticipantIds = inSessionParticipants?.map((p) => p.id);
  return { inSessionParticipants, allExcludedParticipantIds, allInSessionParticipantIds: allParticipantIds };
};

export const StartNewPage: FC<Props> = ({ flows }) => {
  return (
    <Wrapper className="flows">
      <FlowsList flows={flows} />
    </Wrapper>
  );
};
