export enum Orientation {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
}

export enum DeviceSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export function classifyDevice(
  width: number,
  height: number,
): {
  orientation: Orientation;
  size: DeviceSize;
} {
  let orientation = Orientation.PORTRAIT;
  let size = DeviceSize.SMALL;
  const ratio = width / height;

  if (ratio > 1) {
    orientation = Orientation.LANDSCAPE;
  }

  if (width > 740) {
    size = DeviceSize.MEDIUM;
  }

  if (width > 1000) {
    size = DeviceSize.LARGE;
  }

  return {
    orientation,
    size,
  };
}
