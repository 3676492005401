export function isContentStep(step: AnyStep | undefined): step is ContentStep {
  return !!step && (step as ContentStep).type === "ContentStep";
}

export function isChoicesStep(step: AnyStep | undefined): step is ChoicesStep {
  return !!step && (step as ChoicesStep).type === "ChoicesStep";
}

export function isFeedbackScoreStep(step: AnyStep | undefined): step is FeedbackScoreStep {
  return !!step && (step as FeedbackScoreStep).type === "FeedbackScoreStep";
}

export function isFeedbackWordsStep(step: AnyStep | undefined): step is FeedbackWordsStep {
  return !!step && (step as FeedbackWordsStep).type === "FeedbackWordsStep";
}

export function isGoToStep(step: AnyStep | undefined): step is GoToStep {
  return !!step && (step as GoToStep).type === "GoToStep";
}

export function isBranchStep(step: AnyStep | undefined): step is BranchStep {
  return !!step && (step as BranchStep).type === "BranchStep";
}

export function isRoleRotationStep(step: AnyStep | undefined): step is RoleRotationStep {
  return !!step && (step as RoleRotationStep).type === "RoleRotationStep";
}

export function isRoleRandomiserStep(step: AnyStep | undefined): step is RoleRandomiserStep {
  return !!step && (step as RoleRandomiserStep).type === "RoleRandomiserStep";
}

export function isTakeTurnsStep(step: AnyStep | undefined): step is TakeTurnsStep {
  return !!step && (step as TakeTurnsStep).type === "TakeTurnsStep";
}

export function isIframeStep(step: AnyStep | undefined): step is IframeStep {
  return !!step && (step as IframeStep).type === "IframeStep";
}
