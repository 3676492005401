import { useStore } from "../core/store";
import { useShallow } from "zustand/react/shallow";
import { isFeedbackScoreStep, isFeedbackWordsStep, isIframeStep } from "../types/type_guards";

export const useGoToFeedback = () => {
  const { currentStep, lastStep, invitation, core } = useStore(
    useShallow((state) => ({
      currentStep: state.currentStep,
      lastStep: state.lastStep,
      invitation: state.invitation,
      core: state.core,
    })),
  );

  const isSomeonePresent = core.isSomeonePresent;
  const isLobby = invitation.lobby;

  // Requests to leave if the session is over and no one else is present.
  if (
    !isLobby &&
    lastStep &&
    !isSomeonePresent &&
    currentStep &&
    !isIframeStep(currentStep) &&
    !isFeedbackWordsStep(currentStep) &&
    !isFeedbackScoreStep(currentStep)
  ) {
    console.debug("Requesting to end session (todo vg: this has been disabled)", {
      isLobby,
      isSomeonePresent,
      currentStep,
      lastStep,
    });
    // doAction(core, "end_session", -1, core.currentUser.id);
  }
};
