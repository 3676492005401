import postFeeling from "../../lib/requests/postFeeling";
import { Core } from "../core";
import { useStore } from "../store";

// submitFeeling is called when the user submits a feeling score.
// It updates the session state and then re-renders the app.
// We'd rather the user gets in their session even if this fails,
// so we don't catch any errors here. We don't await the request either so
// that we don't block the user from continuing.
export const submitFeeling = async function (feelingScore: number, core: Core) {
  try {
    postFeeling(core.hashedInvitationID, feelingScore);
  } catch {
    console.error("unable to submit feeling score");
  } finally {
    useStore.setState({ invitation: { ...core.invitation, skipFeelingSurvey: true } });
  }
};
