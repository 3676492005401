/* eslint-disable @typescript-eslint/no-unused-vars */
type CableMessage =
  | PresencesMessage
  | UpdateMessage
  | AudioCommand
  | ChatMessage
  | ReloadCommand
  | DropUserCommand
  | GoToUrlMessage
  | BreakoutRoomMessage
  | BreakoutRoomsMessage
  | MuteAllMessage
  | MuteParticipantMessage
  | RoomClosingMessage
  | ToggleHandMessage
  | ToggleRecordingMessage
  | ToastMessage
  | InvitationResponse;

// PresencesMessage indicates an update of all user's presences.
interface PresencesMessage {
  presences: {
    [key: number]: {
      present: boolean;
      authorizations: Authorization[];
      location: "offline" | "ready-room" | "session" | "dropped";
      name: string;
      hashedInvitationID: string;
      breakoutRoomHashId: string;
      dailySessionID: string;
      personalCode: number;
    };
  };
  delta: boolean;
}

// BreakoutRoomMessage is an update about a breakout room that's in progress
export type BreakoutStatus = "new" | "ready" | "ongoing" | "completed" | "closed";
interface BreakoutRoomsMessage {
  rooms: BreakoutRoomMessage[];
}
export interface BreakoutRoomMessage {
  hashId: string;
  roomName: string;
  sectionName: LocaleText;
  stepIndex: number;
  totalSteps: number;
  participantIds: number[];
  status: BreakoutStatus;
  sequenceInstanceHashId: string | undefined;
  updatedAt: string;
  showAnalytics: boolean;
  roomStartTime: string;
  roomClosingTime: string | undefined;
  roomClosingType: RoomClosingType | undefined;
  groupHashId: string | undefined;
  sequenceIndex: number;
  parentHashId: string;
  stepCompleted: boolean | undefined;
}

export type RoomClosingType = "feedback" | "lobby" | "closing" | "estimate";

export interface RoomClosingMessage {
  closingTime: string;
  closingType: RoomClosingType;
}

interface GoToUrlMessage {
  go_to_url: string;
}

type ActionStatus = "NONE" | "PENDING" | "COMPLETED";

type RoleData = {
  [key: string]: { id: number; name: string; role: "A" | "B" | "EVERYONE_ELSE"; actionStatus: ActionStatus };
};

interface UpdateMessage {
  invitation_assignment: AssignedHashedInvitationId;
  user_responses: UserResponses;
  current_step_data: StepIndexData;
  role_data: RoleData;
  audio_progress: number;
  current_step: AnyStep;
  last_step: boolean;
  personal_code: number;
  step_index: number;
  flow_index: number;
  now: string;
}

export interface AudioCommand {
  audio_command: "set_audio_time" | "play" | "pause";
  time?: number;
  skip_toast?: boolean;
  user_name: string;
  user_id: number;
}

export interface ReloadCommand {
  reload_command: "switch_in_person_mode";
  in_person: boolean;
  user_id: string;
}

interface DropUserCommand {
  drop_user: number;
  user_name: string;
  dropped_user_name: string;
}

interface ChatMessage {
  chat: {
    message: string;
    user_id: number;
    name: string;
  };
}

interface MuteAllMessage {
  action: "mute_all";
  muterId: number;
  mutedBy: string;
}

interface MuteParticipantMessage {
  action: "mute_participant";
  mutedBy: string;
  participantId: number;
}

interface ToggleHandMessage {
  action: "raise_hand" | "lower_hand";
  participantId: number;
  toggledByParticipantId: number;
}

interface ToastMessage {
  toastMessage: string;
}

type RecordingMessageAction =
  | "start_recording"
  | "stop_recording"
  | "start_transcription"
  | "start_recording_and_transcription";

interface ToggleRecordingMessage {
  toggledByParticipantId: number;
  toggledByParticipantName: string;
  action: RecordingMessageAction;
  errorMessage: string | undefined;
}

export function isPresencesMessage(u: CableMessage): u is PresencesMessage {
  return (u as PresencesMessage).presences !== undefined;
}

export function isBreakoutRoomsMessage(u: CableMessage): u is BreakoutRoomsMessage {
  return (u as BreakoutRoomsMessage).rooms !== undefined;
}
export function isBreakoutRoomMessage(u: CableMessage): u is BreakoutRoomMessage {
  return (u as BreakoutRoomMessage).roomStartTime !== undefined;
}

export function isGoToUrlMessage(u: CableMessage): u is GoToUrlMessage {
  return (u as GoToUrlMessage).go_to_url !== undefined;
}

export function isRoomClosingMessage(u: CableMessage): u is RoomClosingMessage {
  return (u as RoomClosingMessage).closingTime !== undefined;
}

export function isUpdateMessage(u: CableMessage): u is UpdateMessage {
  return (u as UpdateMessage).current_step !== undefined;
}

export function isAudioCommand(u: CableMessage): u is AudioCommand {
  return (u as AudioCommand).audio_command !== undefined;
}

export function isChatMessage(u: CableMessage): u is ChatMessage {
  return (u as ChatMessage).chat !== undefined;
}

export function isReloadCommand(u: CableMessage): u is ReloadCommand {
  return (u as ReloadCommand).reload_command !== undefined;
}

export function isDropUserCommand(u: CableMessage): u is DropUserCommand {
  return (u as DropUserCommand).drop_user !== undefined;
}

export function isMuteAllMessage(u: CableMessage): u is MuteAllMessage {
  return (u as MuteAllMessage).action === "mute_all";
}

export function isMuteParticipantMessage(u: CableMessage): u is MuteParticipantMessage {
  return (u as MuteParticipantMessage).action === "mute_participant";
}

export function isToggleHandMessage(u: CableMessage): u is ToggleHandMessage {
  return (u as ToggleHandMessage).action === "raise_hand" || (u as ToggleHandMessage).action === "lower_hand";
}

export function isToggleRecordingMessage(u: CableMessage): u is ToggleRecordingMessage {
  return (
    (u as ToggleRecordingMessage).action === "start_recording" ||
    (u as ToggleRecordingMessage).action === "stop_recording" ||
    (u as ToggleRecordingMessage).action === "start_transcription" ||
    (u as ToggleRecordingMessage).action === "start_recording_and_transcription"
  );
}

export function isToastMessage(u: CableMessage): u is ToastMessage {
  return (u as ToastMessage).toastMessage !== undefined;
}

export function isInvitationResponse(u: CableMessage): u is InvitationResponse {
  return (u as InvitationResponse).partnerJoinable !== undefined;
}

export default CableMessage;
