import { useEffect } from "react";
import { useStore } from "../core/store";
import { classifyDevice } from "./classifyDevice";

function getDeviceClassification() {
  const { innerWidth: width, innerHeight: height } = window;

  return classifyDevice(width, height);
}

export function useSetDeviceClassification() {
  useEffect(() => {
    useStore.setState({ deviceClassification: getDeviceClassification() });

    function handleResize() {
      useStore.setState({ deviceClassification: getDeviceClassification() });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
}
