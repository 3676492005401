/* eslint-disable formatjs/no-literal-string-in-jsx */
import React, { FC } from "react";
import ErrorMessage from "./ErrorMessage";
import { AxiosError } from "axios";
import { IntlProvider } from "react-intl";
import { getHashedInvitationID } from "../../../lib/getHashedInvitationID";

export const FallbackError: FC<{ heading?: string; error: Error | AxiosError | unknown | undefined }> = ({
  heading,
  error,
}) => {
  if (
    error instanceof Error &&
    (error as AxiosError).code != "ERR_NETWORK" &&
    error.message.indexOf("status code 401") != -1
  ) {
    window.location.href = `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/fallback-join/${getHashedInvitationID()}`;
    return;
  }

  return (
    <IntlProvider locale={"en"}>
      <ErrorMessage heading={heading || "Unable to load this session"}>
        Try refreshing your browser.
        <br />
        <br /> Check your internet connection.
        <br />
        <br />
        Join from the original link or QR code again.
        <br />
        <br />
        <hr style={{ borderColor: "#333", marginBottom: "20px" }} />
        <small>
          <details>
            <summary>Still not working?</summary>
            <br />
            Send a screenshot of the whole page to us, including the address bar and this technical error message:
            <br />
            <br />
            <code>
              <div
                style={{
                  padding: "10px",
                  boxSizing: "border-box",
                  width: "100%",
                  fontSize: "12px",
                  backgroundColor: "#222",
                }}
              >
                {error instanceof Error ? (
                  <div>
                    <div>{error.message}</div>
                    <div>{error.stack}</div>
                  </div>
                ) : (
                  ""
                )}
                {error instanceof AxiosError ? <div>{error.response?.data.message}</div> : ""}
                {typeof error == "string" ? <div>{error}</div> : ""}
              </div>
            </code>
          </details>
        </small>
      </ErrorMessage>
    </IntlProvider>
  );
};
