import { createIntl, createIntlCache } from "react-intl";
import { DEFAULT_LOCALE } from "../lib/defaults";

import messages_de from "../../lang/de.json";
import messages_fr from "../../lang/fr.json";
import messages_it from "../../lang/it.json";

export type Locales = "en" | "de" | "fr" | "it";
const ValidLocales = ["en", "de", "fr", "it"];

const messages = {
  en: {}, // Use the defaults of the FormatMessage component, no need to have a file for 'en'.
  de: messages_de,
  fr: messages_fr,
  it: messages_it,
};

const cache = createIntlCache();

export const wsCreateIntl = (locale: Locales) => {
  if (!ValidLocales.includes(locale)) {
    locale = DEFAULT_LOCALE;
  }

  return createIntl(
    {
      locale: locale,
      messages: messages[locale],
    },
    cache,
  );
};
