import styled from "styled-components";
import React from "react";
import times from "../../../assets/icons/times-white.svg";
import { millisecondsToStr } from "../../../lib/duration";

const ContinueEarlyNotifcationWrapper = styled.div`
  margin: 0 auto;
  width: calc(100% - 20px);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 8px;
  max-width: 500px;
  background: rgb(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  top: 0px;
  transition: all 0.5s ease 0.5s;

  &.hideMe {
    opacity: 0;
    top: -10px;
    position: relative;
  }

  .progress {
    width: 100%;
    background-color: #555;
    height: 4px;
  }

  &.flashing {
    animation-name: flashing;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transition: none !important;
  }

  @keyframes flashing {
    0% {
      background: rgb(0, 0, 0, 0.4);
    }
    50% {
      background: rgb(255, 0, 0, 0.3);
    }
    100% {
      background: rgb(0, 0, 0, 0.4);
    }
  }

  .progress-bar {
    width: 100%;
    height: 4px;
    background-color: #ccc;
    transition: width 1s linear;
  }

  .duration {
    font-size: 15px;
    margin-right: 5px;
    width: auto;
    flex: 0;
    white-space: nowrap;
  }

  button {
    border: none;
    color: #fff !important;
    background-color: transparent;
    padding: 0px;
    text-decoration: underline;
    font-size: 18px;
    margin-left: 5px;
    position: relative;
    top: 3px;
  }

  .hidden-close-button {
    visibility: hidden;
  }
`;

const ContinueEarlyNotifcationContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  height: auto;
`;

const ContinueEarly = ({
  secondsCountdownTime,
  hideBarTime,
  timeOnStep,
  locale,
  setContinueEarly,
  autoAdvanceSteps,
}: {
  secondsCountdownTime: number;
  hideBarTime: number;
  timeOnStep: number;
  locale: string;
  setContinueEarly: (() => void) | null;
  autoAdvanceSteps: boolean;
}) => {
  if (!secondsCountdownTime) {
    return null;
  }
  let classNames = "";
  if (timeOnStep >= hideBarTime) {
    classNames = "hideMe";
  } else if (timeOnStep + 5 >= hideBarTime) {
    classNames += " flashing";
  }

  return (
    <ContinueEarlyNotifcationWrapper className={"continue-early " + classNames}>
      <ContinueEarlyNotifcationContent>
        <div className="duration">{millisecondsToStr((secondsCountdownTime - timeOnStep) * 1000, locale)}</div>

        <div className="progress">
          <div className="progress-bar" style={{ width: (timeOnStep / secondsCountdownTime) * 100 + "%" }} />
        </div>

        {setContinueEarly && (
          <button
            key="continue-early"
            onClick={() => {
              setContinueEarly && setContinueEarly();
            }}
          >
            <img
              className={autoAdvanceSteps ? "hidden-close-button" : ""}
              src={times} // eslint-disable-next-line formatjs/no-literal-string-in-jsx
              alt="Close button"
              width="13"
              height="17"
            />
          </button>
        )}
      </ContinueEarlyNotifcationContent>
    </ContinueEarlyNotifcationWrapper>
  );
};

export default ContinueEarly;
