import { DailyEventObjectParticipant, DailyEventObjectParticipantLeft } from "@daily-co/daily-js";
import { Core } from "../core";
import { useStore } from "../store";

export const onParticipantLeft = function (this: Core, event?: DailyEventObjectParticipantLeft) {
  console.debug("[session-ui]: participant left");

  if (!event) return;

  // Just a hack to make sure VideoGrid rerenders.
  useStore.setState({ participants: Object.assign({}, useStore.getState().participants) });
};

export const onParticipantJoined = function (this: Core, event?: DailyEventObjectParticipant) {
  if (!event) return;
  console.debug("[session-ui]: participant joined", event);

  // Just a hack to make sure VideoGrid rerenders.
  useStore.setState({ participants: Object.assign({}, useStore.getState().participants) });
};
