import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { ScreenShare } from "@daily-co/daily-react";

interface ScreenshareTileProps {
  screen: ScreenShare;
}

export const ScreenshareTileContainer = styled.div`
  box-sizing: border-box;
  background-color: #000;
  height: 100%;
  width: 100%;

  overflow: hidden;
  position: relative;
  border-radius: 10px;

  transition: width 0.3s ease;

  video {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`;

export const ScreenshareTile: FC<ScreenshareTileProps> = ({ screen }) => {
  const hasVideo = screen.screenVideo.state === "playable";

  const videoTrack = screen.screenVideo;
  const videoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoElement.current;
    if (!video || !videoTrack?.persistentTrack) return;

    /*  The track is ready to be played. We can show video of the participant in the UI.*/
    video.srcObject = new MediaStream([videoTrack?.persistentTrack]);
  }, [videoTrack?.persistentTrack, hasVideo]);

  return (
    <ScreenshareTileContainer className="videoGridTile screenshareTile" data-id={screen.screenId}>
      <div style={{ width: "100%", height: "100%" }}>
        <div className="scrollGrabber"></div>
        {hasVideo && <video autoPlay muted playsInline disablePictureInPicture ref={videoElement} />}
      </div>
    </ScreenshareTileContainer>
  );
};
