import styled from "styled-components";

export const SetupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 25px 25px;
  box-sizing: border-box;

  h3 {
    font-weight: normal;
  }

  #joinButton {
    min-width: 150px;
  }
`;

export const SetupInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 550px;
`;

export const CameraAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
`;

export const CameraBox = styled.div`
  background-color: #000;
  position: relative;
  display: block;
  flex-shrink: 0;

  width: 100%;
  border-radius: 10px;

  video {
    margin: 0px;
    transform: rotateY(180deg);
  }
`;

export const Camera = styled.div`
  padding-top: 75%;
  height: 100%;
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }
`;

export const CameraState = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  line-height: 1.6em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 375px) {
    font-size: 14px;
    small {
      font-size: 12px;
    }
  }
`;

export const Toolbox = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #111;
  overflow: hidden;
`;

export const InstructionWrapper = styled.div`
  display: flex;
  height: 100%;
  height: 48px;
  flex-wrap: wrap;
  align-items: center;

  text-align: center;

  .innerText {
    padding: 10px 0px 0px 0px;
  }

  .goBackbutton {
    border: none;
    background-color: transparent;
    padding: 0px;
    text-decoration: underline;
    font-size: 18px;
    box-shadow: none !important;
    padding: 0px !important;
    font-weight: normal !important;
    text-transform: none;
  }
`;
