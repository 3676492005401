export interface AudioFiles {
  [url: string]: HTMLAudioElement;
}

export const loadNarrationAudios = async function (audioPaths: string[]) {
  console.debug("[session-ui]: Loading narration.");
  const response: AudioFiles = {};
  const loadingPromises: Promise<boolean>[] = [];

  audioPaths.forEach((audioPath) => {
    const audioElement = new Audio(audioPath);
    audioElement.load();
    audioElement.crossOrigin = "anonymous";

    const loadingPromise = new Promise<boolean>((resolve, reject) => {
      audioElement.addEventListener("canplaythrough", () => {
        resolve(true);
      });

      audioElement.addEventListener("error", function (e) {
        console.error(e);
        reject(e);
      });
    });

    loadingPromises.push(loadingPromise);

    response[audioPath] = audioElement;
  });

  try {
    await Promise.all(loadingPromises);
  } catch (e) {
    console.error(e);
    // throw new Error(`Unable to load narration audio files.`);
  }

  return response;
};
