import * as React from "react";
const BlurIcon = ({ width, height, fill }: { width: string; height: string; fill: string }) => (
  <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 522 522" style={{ width: width, height: height }}>
    <g>
      <path
        style={{ fill: fill }}
        d="M141,16c8.8,0,16,7.2,16,16s-7.2,16-16,16c-8.8,0-16-7.2-16-16S132.2,16,141,16z M261,16c8.8,0,16,7.2,16,16
       s-7.2,16-16,16c-8.8,0-16-7.2-16-16S252.2,16,261,16z M381,16c8.8,0,16,7.2,16,16s-7.2,16-16,16s-16-7.2-16-16S372.2,16,381,16z
        M501,136c8.8,0,16,7.2,16,16c0,8.8-7.2,16-16,16s-16-7.2-16-16C485,143.2,492.2,136,501,136z M501,256c8.8,0,16,7.2,16,16
       s-7.2,16-16,16s-16-7.2-16-16S492.2,256,501,256z M501,376c8.8,0,16,7.2,16,16s-7.2,16-16,16s-16-7.2-16-16S492.2,376,501,376z
        M21,376c8.8,0,16,7.2,16,16s-7.2,16-16,16s-16-7.2-16-16S12.2,376,21,376z M21,256c8.8,0,16,7.2,16,16s-7.2,16-16,16
       s-16-7.2-16-16S12.2,256,21,256z M21,136c8.8,0,16,7.2,16,16c0,8.8-7.2,16-16,16s-16-7.2-16-16C5,143.2,12.2,136,21,136z M149,128
       c17.7,0,32,14.3,32,32s-14.3,32-32,32s-32-14.3-32-32S131.3,128,149,128z M77,64c13.3,0,24,10.7,24,24s-10.7,24-24,24
       s-24-10.7-24-24S63.7,64,77,64z M205,64c13.3,0,24,10.7,24,24s-10.7,24-24,24s-24-10.7-24-24S191.7,64,205,64z M317,64
       c13.3,0,24,10.7,24,24s-10.7,24-24,24s-24-10.7-24-24S303.7,64,317,64z M445,64c13.3,0,24,10.7,24,24s-10.7,24-24,24
       s-24-10.7-24-24S431.7,64,445,64z M445,192c13.3,0,24,10.7,24,24s-10.7,24-24,24s-24-10.7-24-24S431.7,192,445,192z M445,304
       c13.3,0,24,10.7,24,24s-10.7,24-24,24s-24-10.7-24-24S431.7,304,445,304z M445,432c13.3,0,24,10.7,24,24s-10.7,24-24,24
       s-24-10.7-24-24S431.7,432,445,432z M77,432c13.3,0,24,10.7,24,24s-10.7,24-24,24s-24-10.7-24-24S63.7,432,77,432z M77,304
       c13.3,0,24,10.7,24,24s-10.7,24-24,24s-24-10.7-24-24S63.7,304,77,304z M77,192c13.3,0,24,10.7,24,24s-10.7,24-24,24
       s-24-10.7-24-24S63.7,192,77,192z M373,128c17.7,0,32,14.3,32,32s-14.3,32-32,32s-32-14.3-32-32S355.3,128,373,128z M373,240
       c17.7,0,32,14.3,32,32s-14.3,32-32,32s-32-14.3-32-32S355.3,240,373,240z M149,240c17.7,0,32,14.3,32,32s-14.3,32-32,32
       s-32-14.3-32-32S131.3,240,149,240z M149,352c17.7,0,32,14.3,32,32s-14.3,32-32,32s-32-14.3-32-32S131.3,352,149,352z M373,352
       c17.7,0,32,14.3,32,32s-14.3,32-32,32s-32-14.3-32-32S355.3,352,373,352z"
      />
      <path
        style={{ fill: fill }}
        d="M149,128c-1.1,0-2.3,0.1-3.4,0.2c2.9,4.8,4.5,10.4,4.5,16.4c0,16.5-12.5,30.1-28.6,31.8
       c5.6,9.4,15.8,15.6,27.5,15.6c17.7,0,32-14.3,32-32S166.7,128,149,128z M373,128c-1.1,0-2.3,0.1-3.4,0.2c2.9,4.8,4.5,10.4,4.5,16.4
       c0,16.5-12.5,30.1-28.6,31.8c5.6,9.4,15.8,15.6,27.5,15.6c17.7,0,32-14.3,32-32S390.7,128,373,128z M373,240
       c-1.1,0-2.3,0.1-3.4,0.2c2.9,4.8,4.5,10.4,4.5,16.4c0,16.5-12.5,30.1-28.6,31.8c5.6,9.4,15.8,15.6,27.5,15.6c17.7,0,32-14.3,32-32
       S390.7,240,373,240L373,240z M149,240c-1.1,0-2.3,0.1-3.4,0.2c2.9,4.8,4.5,10.4,4.5,16.4c0,16.5-12.5,30.1-28.6,31.8
       c5.6,9.4,15.8,15.6,27.5,15.6c17.7,0,32-14.3,32-32S166.7,240,149,240L149,240z M149,352c-1.1,0-2.3,0.1-3.4,0.2
       c2.9,4.8,4.5,10.4,4.5,16.4c0,16.5-12.5,30.1-28.6,31.8c5.6,9.4,15.8,15.6,27.5,15.6c17.7,0,32-14.3,32-32S166.7,352,149,352
       L149,352z M373,352c-1.1,0-2.3,0.1-3.4,0.2c2.9,4.8,4.5,10.4,4.5,16.4c0,16.5-12.5,30.1-28.6,31.8c5.6,9.4,15.8,15.6,27.5,15.6
       c17.7,0,32-14.3,32-32S390.7,352,373,352L373,352z"
      />
    </g>
    <path
      style={{ fill: fill }}
      d="M261,91.5c-61.2,0-111,49.8-111,111s49.8,111,111,111s111-49.8,111-111S322.2,91.5,261,91.5z M399.1,384
   c-30.4-30.9-70.7-47.8-113.4-47.8h-49.3c-42.8,0-83,17-113.4,47.8C92.7,414.7,76,455.3,76,498.2c0,6.8,5.5,12.3,12.3,12.3h345.3
   c6.8,0,12.3-5.5,12.3-12.3C446,455.3,429.3,414.7,399.1,384z"
    />
  </svg>
);

export default BlurIcon;
