import axios from "axios";

interface PostBreakoutSendRequest {
  lobbyInvitationHashId: string;
  roomInvitationHashId: string;
  includeUserIds: number[];
}

export default async (args: PostBreakoutSendRequest): Promise<any> => {
  return axios
    .post(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/breakout/send`, args, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
};
