import { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "../core/store";

export function useHandleNoSleep() {
  let wakeLockAPISupport = false;
  if ("wakeLock" in navigator) {
    wakeLockAPISupport = true;
  }

  const [noSleepEnabled, setNoSleepEnabled] = useState(false);
  const narrating = useStore((state) => state.narrating);
  const narratingRef = useRef<boolean>(narrating);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  const handleNoSleep = useCallback(async () => {
    if (wakeLockRef.current) return;

    if (wakeLockAPISupport) {
      navigator.wakeLock
        .request("screen")
        .then((wakeLock) => {
          console.debug("[session-ui]: Wakelock acquired");
          wakeLockRef.current = wakeLock;
        })
        .catch((err) => {
          console.debug("[session-ui]: Wakelock request failed. Fallback click listener will be installed", err);
        });
    } else {
      if (!noSleepEnabled) {
        console.debug("[session-ui]: Enabling nosleep");
        const videoElement = document.getElementById("nosleepvideo") as HTMLVideoElement;

        if (videoElement) {
          await videoElement.play();
          setNoSleepEnabled(true);

          // Minimum screen wake interval is 30 seconds on iOS.
          // So playing the video only every 20 second should be ok.
          intervalRef.current = setInterval(() => {
            console.debug("[session-ui]: No sleep check. Currently narrating:", narratingRef.current);
            if (!narratingRef.current) {
              console.debug("[session-ui]: Triggering nosleep video to play");
              videoElement.play();
            }
          }, 20000);
        }
      }
    }
  }, [noSleepEnabled, setNoSleepEnabled]);

  useEffect(() => {
    narratingRef.current = narrating;
  }, [narrating]);

  useEffect(() => {
    if (wakeLockAPISupport) {
      // Try to acquire without a click if possible.
      console.debug("[session-ui]: Native wakelock support detected");
      navigator.wakeLock
        .request("screen")
        .then((wakeLock) => {
          console.debug("[session-ui]: Wakelock acquired");
          wakeLockRef.current = wakeLock;
        })
        .catch((err) => {
          console.debug("[session-ui]: Wakelock request failed. Fallback click listener will be installed", err);
        });
    }

    if (!noSleepEnabled) {
      console.debug("[session-ui]: Adding fallback nosleep click listener");
      document.addEventListener("click", handleNoSleep);
    }

    return () => {
      console.debug("[session-ui]: Removing nosleep click listener");
      document.removeEventListener("click", handleNoSleep);
    };
  }, [noSleepEnabled, handleNoSleep]);

  // Re-acquire wakelock if the user switches tabs and comes back.
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (wakeLockRef.current !== null && document.visibilityState === "visible") {
        navigator.wakeLock
          .request("screen")
          .then((wakeLock) => {
            console.debug("[session-ui]: Wakelock acquired");
            wakeLockRef.current = wakeLock;
          })
          .catch((err) => {
            console.debug("[session-ui]: Wakelock request failed after switching tab", err);
          });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);
}
