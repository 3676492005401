import React, { FC, useRef, useState } from "react";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { TextInput } from "grommet";
import { LoadingButton } from "../ButtonV2/Button";
import { Core } from "../../../core/core";

interface Props {}

const Wrapper = styled.div`
  font-size: 15px;
  margin-top: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  position: relative;
  top: 10px;

  background-color: rgba(0, 0, 0, 0.05);
  margin-left: 10px;
  padding: 10px;
  border-radius: 10px;

  small {
    margin-top: 5px;
    font-size: 15px;
    color: #333;
  }

  &.addPartner {
    color: rgb(255, 119, 41);
    font-size: 15px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.09);
      cursor: pointer;
    }
  }
`;

const InputLine = styled.span`
  width: 150px;
  display: flex;
  margin-top: 5px;

  input,
  button {
    padding: 3px;
    border-radius: 8px;
    text-align: center;
  }
  button {
    font-size: 15px;
    padding: 3px 5px;
    margin-left: 5px;
  }
  input::placeholder {
    color: #666;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const AddPartner: FC<Props> = () => {
  const { participants, showAddPartner, invitation, currentUser } = useStore(
    useShallow((state) => ({
      participants: state.participants,
      showAddPartner: state.showAddPartner,
      invitation: state.invitation,
      currentUser: state.currentUser,
    })),
  );

  const curParticipant = participants[currentUser.id];

  const inputRef = useRef(null);
  const [buttonLoading, setButtonLoading] = useState<boolean | null>(null);

  if (!showAddPartner || !curParticipant?.personalCode || invitation.lobby || !invitation.partnerJoinable) {
    return;
  }

  function handleSubmit() {
    setButtonLoading(true);
    Core.GetInstance().subscription?.perform(
      "add_partner",
      {
        partnerCode: (inputRef.current! as HTMLInputElement).value,
      },
      () => setButtonLoading(false),
    );
  }

  return (
    <Wrapper>
      <small>
        <FormattedMessage
          id="add_partner.your_personal_code"
          defaultMessage="Your code: {personalCode}"
          values={{ personalCode: curParticipant.personalCode }}
        />
      </small>

      <InputLine>
        <TextInput ref={inputRef} placeholder={"partner code"} type="number" />
        <LoadingButton onClick={handleSubmit} loading={buttonLoading == true}>
          <FormattedMessage
            description="Add Partner button submit text"
            id="add_partner.content_submit"
            defaultMessage="Add"
          />
        </LoadingButton>
      </InputLine>
    </Wrapper>
  );
};
