import React, { forwardRef, useEffect, useRef } from "react";
import styled from "styled-components";
import Username from "../Username/Username";
import { useMediaTrack, useParticipantProperty } from "@daily-co/daily-react";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import TileNoVideo from "./TileNoVideo";

interface VideoGridTileProps {
  dailySessionID: string;
  warmspaceID: number;
  participantLocation: ParticipantLocation;
  userName: string;
}

export const VideoGridTileContainer = styled.div`
  box-sizing: border-box;
  background-color: #000;
  height: 100%;
  width: 100%;

  overflow: hidden;
  position: relative;
  border-radius: 10px;

  transition: width 0.3s ease;

  video {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`;

export const VideoGridTile = forwardRef<HTMLDivElement, VideoGridTileProps>(function VideoGridTile(
  { dailySessionID, warmspaceID, participantLocation, userName },
  ref,
) {
  const trackState = useParticipantProperty(dailySessionID, "tracks");
  const local = useParticipantProperty(dailySessionID, "local");

  const hasVideo = trackState?.video.state === "playable";

  const videoTrack = useMediaTrack(dailySessionID, "video");
  const videoElement = useRef<HTMLVideoElement>(null);

  const { roleData } = useStore(
    useShallow((state) => ({
      narrating: state.narrating,
      roleData: state.roleData,
    })),
  );

  useEffect(() => {
    const video = videoElement.current;
    if (!video || !videoTrack?.persistentTrack) return;

    /*  The track is ready to be played. We can show video of the participant in the UI.*/
    video.srcObject = new MediaStream([videoTrack?.persistentTrack]);
  }, [videoTrack?.persistentTrack, hasVideo]);

  return (
    <VideoGridTileContainer ref={ref} data-id={dailySessionID} className={"videoGridTile participantTile"}>
      <div className="scrollGrabber"></div>

      {hasVideo && <video autoPlay muted playsInline disablePictureInPicture ref={videoElement} />}
      {!hasVideo && (
        <TileNoVideo
          participantLocation={participantLocation}
          name={userName}
          isLocal={false}
          dropParticipant={() => {
            useStore.setState({ showDropParticipantModal: warmspaceID });
          }}
        />
      )}

      {!local && (
        <Username
          name={userName}
          participantLocation={participantLocation}
          solo={false}
          isLocal={false}
          dailyID={dailySessionID}
          roleData={roleData[warmspaceID]}
          userId={warmspaceID}
        />
      )}
    </VideoGridTileContainer>
  );
});
