import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 96px;
  height: 96px;
  margin-bottom: 20px;

  .circle {
    position: absolute;
    top: 0px;
    opacity: 0%;
    left: 0px;
    animation: ripple 4s linear infinite;
    will-change: transform, opacity;
    transform: scale(0);
  }

  .circle:nth-child(2) {
    animation-delay: -1s;
  }

  @keyframes ripple {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    50% {
      opacity: 1;
    }

    90% {
      opacity: 0;
      transform: scale(1);
    }

    100% {
      opacity: 0;
    }
  }
`;
export const Ripple = () => {
  return (
    <Wrapper className="ripple">
      <svg className="circle" height="96px" width="96px">
        <circle cx="48" cy="48" r="46" stroke="white" strokeWidth="5" fill="transparent" />
      </svg>

      <svg className="circle" height="96px" width="96px">
        <circle cx="48" cy="48" r="46" stroke="white" strokeWidth="5" fill="transparent" />
      </svg>
    </Wrapper>
  );
};
