import React, { memo, useCallback, useState } from "react";
import { useDailyEvent, useMediaTrack } from "@daily-co/daily-react";
import AudioLevelInner from "./AudioLevelInner";
import { ElementPosition } from "./AudioLevelInner";
import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";

export default memo(function AudioLevel({
  id,
  position,
  local,
}: {
  id: string;
  position: ElementPosition;
  local: boolean;
}) {
  const audioTrack = useMediaTrack(id, "audio");
  const hasAudio = audioTrack.state === "playable";
  const [volume, setVolume] = useState(0);

  const { narrating } = useStore(
    useShallow((state) => ({
      narrating: state.narrating,
    })),
  );

  useDailyEvent(
    "remote-participants-audio-level",
    useCallback(
      (ev) => {
        if (id in ev.participantsAudioLevel && !local) {
          setVolume(ev.participantsAudioLevel[id]);
        }
      },
      [id, local, setVolume],
    ),
  );

  useDailyEvent(
    "local-audio-level",
    useCallback(
      (ev) => {
        if (local) {
          setVolume(ev.audioLevel);
        }
      },
      [id, local, setVolume],
    ),
  );

  return audioTrack.persistentTrack ? (
    <AudioLevelInner position={position} volume={volume} hasAudio={hasAudio && !narrating} />
  ) : null;
});
