import styled from "styled-components";

import React, { FC, useEffect, useRef, useState } from "react";
import { Button, LoadingButton } from "../ButtonV2/Button";

import { useStore } from "../../../core/store";
import { useShallow } from "zustand/react/shallow";
import { TextInput } from "grommet";
import { FormattedMessage } from "react-intl";
import { doAction } from "../../../core/SessionChannel/doAction";
import ContinueEarly from "../Actions/ContinueEarly";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  justify-content: center;
  align-items: center;

  transition: all 0.5s ease;
  top: -100px;
  opacity: 0;

  &.showContent {
    top: 0;
    opacity: 1;
  }

  &.inPerson {
    height: calc(100% - 60px);
    top: 0;
    &.showContent {
      top: 100px;
    }

    p {
      color: #2a2a2a;
    }
  }

  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.35;
    color: white;
    margin: 0 auto;
    white-space: pre-line;
    font-family: "ITC Clearface", sans-serif;
    z-index: 10;
    max-width: 90%;
  }

  .continue-early {
    margin-top: 30px;
  }
`;

const WordsText = styled.p`
  margin-bottom: 30px !important;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  display: flex;
  text-align: center;
  justify-content: center;
  z-index: 7;
  opacity: 0.6;
  pointer-events: none;
`;

const WordsWrapper = styled.div`
  z-index: 8;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #222;
  border-radius: 10px;
  padding: 20px;

  width: calc(100% - 20px);
  max-width: 600px;
  box-sizing: border-box;

  &.inPerson {
    background-color: transparent;
  }

  input {
    background: #fff;
    opacity: 0.9;
  }

  &.video {
    input {
      color: #111;
    }
  }
`;

const InputWrapper = styled.span`
  margin-bottom: 20px;
  display: block;
  width: 100%;

  @media (max-width: 800px) {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }
`;

interface Props {
  stepData: StepIndexData;
  yourID: number;
  step: AnyStep;
  stepContent: StepContent;
  text?: string;
}

const FeedbackWordsStep: FC<Props> = ({ stepData, yourID, step, stepContent, text }) => {
  const { inPerson, lastStep, timeOnStep, stepTiming, locale, invitation, core } = useStore(
    useShallow((state) => ({
      core: state.core,
      inPerson: state.inPerson,
      lastStep: state.lastStep,
      timeOnStep: state.timeOnStep,
      stepTiming: state.stepTiming,
      locale: state.locale,
      invitation: state.invitation,
    })),
  );
  const inputRef = useRef(null);
  const [buttonLoading, setButtonLoading] = useState<boolean | null>(null);

  function handleSubmit() {
    setButtonLoading(true);
    doAction(core, "done", step.id, yourID, (inputRef.current! as HTMLInputElement).value, setButtonLoading);
  }

  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    setShowContent(false);
    const timeout = setTimeout(() => {
      setShowContent(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [stepContent?.text]);

  // If we're out of time, then send whatever is in the text box
  const youDone = stepData?.[core.currentUser.id]?.done;
  if (!youDone && stepTiming && !inPerson && timeOnStep >= stepTiming && invitation.autoAdvanceSteps) {
    doAction(
      core,
      "done",
      step.id,
      yourID,
      inputRef.current ? (inputRef.current! as HTMLInputElement).value : "",
      setButtonLoading,
    );
  }

  // If we're done
  if (stepData && stepData[yourID]?.done) {
    console.debug("[FeedbackScoreStep] we're done so returning actions");
    return (
      <Wrapper className="showContent">
        <Button className="secondary" disabled={true}>
          <FormattedMessage
            description="Feedback Words Step button text"
            id="feedback_words_step.content_waiting_for_partner"
            defaultMessage="Waiting for partner"
          />
        </Button>
      </Wrapper>
    );
  }

  const classes: string[] = [];
  if (inPerson) classes.push("inPerson");
  if (showContent) classes.push("showContent");
  const classesString = classes.join(" ");
  return (
    <Wrapper className={classesString}>
      {!inPerson && <Overlay />}

      <WordsWrapper id="question-cards-wrapper" key="scoreslist" className={inPerson ? "inPerson" : "video"}>
        {text && <WordsText>{text}</WordsText>} {/* needed in in-person mode */}
        <InputWrapper>
          <TextInput ref={inputRef} placeholder={stepContent.placeholder} />
        </InputWrapper>
        <LoadingButton onClick={handleSubmit} loading={buttonLoading == true}>
          {/* TODO from Vlad: Oli, why isn't the FormattedMessage working? */}
          {lastStep ? (
            <FormattedMessage
              description="Feedback Words Step button submit text"
              id="feedback_words_step.content_submit_and_end"
              defaultMessage="Submit and end"
            />
          ) : (
            <FormattedMessage
              description="Feedback Words Step button submit text"
              id="feedback_words_step.content_submit"
              defaultMessage="Submit"
            />
          )}
        </LoadingButton>
        {!inPerson && (
          <ContinueEarly
            secondsCountdownTime={stepTiming}
            hideBarTime={stepTiming}
            timeOnStep={timeOnStep}
            locale={locale}
            setContinueEarly={null}
            autoAdvanceSteps={invitation.autoAdvanceSteps}
          />
        )}
      </WordsWrapper>
    </Wrapper>
  );
};

export default FeedbackWordsStep;
