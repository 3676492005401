import axios from "axios";

interface PostBreakoutReturnRequest {
  roomInvitationHashId: string;
  inqUserId: number;
}

export default async (args: PostBreakoutReturnRequest): Promise<any> => {
  return axios
    .post(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/breakout/return`, args, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
};
